import {QuickButtonProps} from "../../../utilities/constants";

function MyQuickButton(props: QuickButtonProps){
    return(
        <>
            <div className="py-3">
                <a href={props.href}>
                    {props.icon}<br />
                    {props.label}
                </a>
            </div>
        </>
    )
}
export default MyQuickButton;