import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import MyNavbar from "../../shared/admin/myNavbar/myNavbar";
import MyFooter from "../../shared/default/myFooter/myFooter";
import userService from "../../../services/userService";
import {isLoggedUser} from "../../utilities/constants";
import Loader from "../../shared/admin/loader/loader";

type ContextType = { user: string, role: string, loggedUser: isLoggedUser };

const AdminLayout = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = React.useState<string | null>("");
    const [role, setRole] = useState("");
    const [loggedUser, setLoggedUser] = useState<isLoggedUser>();
    const location = useLocation().pathname;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Attiva il loader prima di eseguire la chiamata asincrona
                const response = await userService.isLogged();
                if (location === "/admin" && response.isLogged && response.role != null) {
                    console.log("Utente loggato - home");
                    setUser(response.firstName);
                    setRole(response.roleName);
                    setLoggedUser(response);
                } else if (location !== "/admin" && response.isLogged && response.role != null) {
                    console.log("Utente loggato - nella pagina richiesta");
                    setUser(response.firstName);
                    setRole(response.roleName);
                    setLoggedUser(response);
                } else {
                    if (location === "/admin/new-password" || location === "/admin/reset-password") {
                        console.log("Utente non loggato - nella pagina new o reset password");
                    } else {
                        console.log("Utente non loggato - redirect alla login");
                        navigate("/login");
                        window.location.reload();
                    }
                }
            } catch (error) {
                console.log("errore in userService.isLogged()");
                navigate("/login");
                window.location.reload();
            } finally {
                setLoading(false); // Disattiva il loader dopo che la chiamata asincrona è completata
            }
        };

        fetchData();
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            {loading && <Loader />}
            <div style={{ opacity: loading ? 0.75 : 1, pointerEvents: loading ? 'none' : 'auto' }}>
                <div className="app">
                    <MyNavbar user={user} />
                    <div className="content">
                        <Outlet context={{ user, role, loggedUser }}/>
                    </div>
                    <MyFooter />
                </div>
            </div>
        </div>
    );
};
export function useUser() {
    return useOutletContext<ContextType>();
}
export default AdminLayout;