import { Image } from "react-grid-gallery";
import angoloMorbido1 from "../../../../img/spazi/angolo morbido 1.jpg";
import angoloMorbido1Thumb from "../../../../img/spazi/angolo morbido 1-thumb.jpg";
import angoloMorbido2 from "../../../../img/spazi/angolo morbido 2.jpg";
import angoloMorbido2Thumb from "../../../../img/spazi/angolo morbido 2-thumb.jpg";
import angoloMorbido3 from "../../../../img/spazi/angolo morbido 3.jpg";
import angoloMorbido3Thumb from "../../../../img/spazi/angolo morbido 3-thumb.jpg";
import angoloMorbido4 from "../../../../img/spazi/angolo morbido 4.jpg";
import angoloMorbido4Thumb from "../../../../img/spazi/angolo morbido 4-thumb.jpg";
import angoloMorbido5 from "../../../../img/spazi/angolo morbido 5.jpg";
import angoloMorbido5Thumb from "../../../../img/spazi/angolo morbido 5-thumb.jpg";
import angoloMorbido6 from "../../../../img/spazi/angolo morbido 6.jpg";
import angoloMorbido6Thumb from "../../../../img/spazi/angolo morbido 6-thumb.jpg";
import areaLattanti1 from "../../../../img/spazi/area lattanti 1.jpg";
import areaLattanti1Thumb from "../../../../img/spazi/area lattanti 1-thumb.jpg";
import areaLattanti2 from "../../../../img/spazi/area lattanti 2.jpg";
import areaLattanti2Thumb from "../../../../img/spazi/area lattanti 2-thumb.jpg";
import areaLattanti4 from "../../../../img/spazi/area lattanti 4.jpg";
import areaLattanti4Thumb from "../../../../img/spazi/area lattanti 4-thumb.jpg";
import zonaGiocoSimbolico from "../../../../img/spazi/zona gioco simbolico.jpg";
import zonaGiocoSimbolicoThumb from "../../../../img/spazi/zona gioco simbolico-thumb.jpg";
import zonaGiocoSimbolico2 from "../../../../img/spazi/zona gioco simbolico 2.jpg";
import zonaGiocoSimbolico2Thumb from "../../../../img/spazi/zona gioco simbolico 2-thumb.jpg";
import zonaGiocoSimbolico3 from "../../../../img/spazi/zona gioco simbolico 3.jpg";
import zonaGiocoSimbolico3Thumb from "../../../../img/spazi/zona gioco simbolico 3-thumb.jpg";
import zonaGiocoSimbolico4 from "../../../../img/spazi/zona gioco simbolico 4.jpg";
import zonaGiocoSimbolico4Thumb from "../../../../img/spazi/zona gioco simbolico 4-thumb.jpg";
import zonaPastoEGrandeCostruttivita from "../../../../img/spazi/zona pasto e grande costruttivita.jpg";
import zonaPastoEGrandeCostruttivitaThumb from "../../../../img/spazi/zona pasto e grande costruttivita-thumb.jpg";
import zonaPranzoPiccoli1 from "../../../../img/spazi/zona pranzo piccoli 1.jpg";
import zonaPranzoPiccoli1Thumb from "../../../../img/spazi/zona pranzo piccoli 1-thumb.jpg"
import zonaPranzoPiccoli2 from "../../../../img/spazi/zona pranzo piccoli 2.jpg";
import zonaPranzoPiccoli2Thumb from "../../../../img/spazi/zona pranzo piccoli 2-thumb.jpg"
import zonaPranzoPiccoli3 from "../../../../img/spazi/zona pranzo piccoli 3.jpg";
import zonaPranzoPiccoli3Thumb from "../../../../img/spazi/zona pranzo piccoli 3-thumb.jpg"

export interface CustomImage extends Image {
    original: string;
}

export const images: CustomImage[] = [
    {
        src: angoloMorbido1Thumb,
        original: angoloMorbido1,
        width: 400,
        height: 226,
        tags: [
            { value: "Angolo morbido", title: "Angolo morbido" },
            { value: "Lettura", title: "Lettura" },
        ],
        caption: "Zona angolo morbido e lettura",
    },
    {
        src: angoloMorbido2Thumb,
        original: angoloMorbido2,
        width: 400,
        height: 226,
        tags: [
            { value: "Angolo morbido", title: "Angolo morbido" },
            { value: "Lettura", title: "Lettura" },
        ],
        caption: "Zona angolo morbido e lettura",
    },
    {
        src: angoloMorbido3Thumb,
        original: angoloMorbido3,
        width: 400,
        height: 226,
        tags: [
            { value: "Angolo morbido", title: "Angolo morbido" },
            { value: "Lettura", title: "Lettura" },
        ],
        caption: "Zona angolo morbido e lettura",
    },
    {
        src: angoloMorbido4Thumb,
        original: angoloMorbido4,
        width: 400,
        height: 226,
        tags: [
            { value: "Angolo morbido", title: "Angolo morbido" },
            { value: "Lettura", title: "Lettura" },
        ],
        caption: "Zona angolo morbido e lettura",
    },
    {
        src: angoloMorbido5Thumb,
        original: angoloMorbido5,
        width: 400,
        height: 226,
        tags: [
            { value: "Angolo morbido", title: "Angolo morbido" },
            { value: "Lettura", title: "Lettura" },
        ],
        caption: "Zona angolo morbido e lettura",
    },
    {
        src: angoloMorbido6Thumb,
        original: angoloMorbido6,
        width: 400,
        height: 226,
        tags: [
            { value: "Angolo morbido", title: "Angolo morbido" },
            { value: "Lettura", title: "Lettura" },
        ],
        caption: "Zona angolo morbido e lettura",
    },
    {
        src: areaLattanti1Thumb,
        original: areaLattanti1,
        width: 400,
        height: 226,
        tags: [
            { value: "Lattanti", title: "Lattanti" },
        ],
        caption: "Area lattanti",
    },
    {
        src: areaLattanti2Thumb,
        original: areaLattanti2,
        width: 400,
        height: 226,
        tags: [
            { value: "Lattanti", title: "Lattanti" },
        ],
        caption: "Area lattanti",
    },
    {
        src: areaLattanti4Thumb,
        original: areaLattanti4,
        width: 400,
        height: 226,
        tags: [
            { value: "Lattanti", title: "Lattanti" },
        ],
        caption: "Area lattanti",
    },
    {
        src: zonaGiocoSimbolicoThumb,
        original: zonaGiocoSimbolico,
        width: 400,
        height: 226,
        tags: [
            { value: "Gioco simbolico", title: "Gioco simbolico" },
        ],
        caption: "Zona gioco simbolico",
    },
    {
        src: zonaGiocoSimbolico2Thumb,
        original: zonaGiocoSimbolico2,
        width: 400,
        height: 226,
        tags: [
            { value: "Gioco simbolico", title: "Gioco simbolico" },
        ],
        caption: "Zona gioco simbolico",
    },
    {
        src: zonaGiocoSimbolico3Thumb,
        original: zonaGiocoSimbolico3,
        width: 400,
        height: 226,
        tags: [
            { value: "Gioco simbolico", title: "Gioco simbolico" },
        ],
        caption: "Zona gioco simbolico",
    },
    {
        src: zonaGiocoSimbolico4Thumb,
        original: zonaGiocoSimbolico4,
        width: 400,
        height: 226,
        tags: [
            { value: "Gioco simbolico", title: "Gioco simbolico" },
        ],
        caption: "Zona gioco simbolico",
    },
    {
        src: zonaPastoEGrandeCostruttivitaThumb,
        original: zonaPastoEGrandeCostruttivita,
        width: 400,
        height: 226,
        tags: [
            { value: "Zona pasto", title: "Zona pasto" },
            { value: "Zona grande costruttività", title: "Zona grande costruttività" },
        ],
        caption: "Zona pasto e grande costruttività",
    },
    {
        src: zonaPranzoPiccoli1Thumb,
        original: zonaPranzoPiccoli1,
        width: 400,
        height: 226,
        tags: [
            { value: "Zona pranzo piccoli", title: "Zona pranzo piccoli" },
        ],
        caption: "Zona pranzo piccoli",
    },
    {
        src: zonaPranzoPiccoli2Thumb,
        original: zonaPranzoPiccoli2,
        width: 400,
        height: 226,
        tags: [
            { value: "Zona pranzo piccoli", title: "Zona pranzo piccoli" },
        ],
        caption: "Zona pranzo piccoli",
    },
    {
        src: zonaPranzoPiccoli3Thumb,
        original: zonaPranzoPiccoli3,
        width: 400,
        height: 226,
        tags: [
            { value: "Zona pranzo piccoli", title: "Zona pranzo piccoli" },
        ],
        caption: "Zona pranzo piccoli",
    },
];