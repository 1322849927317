import React, {useEffect, useState} from "react";
import { Button, Form } from "react-bootstrap";
import MyTextInput from "../../default/myTextInput/myTextInput";
import userService from "../../../../services/userService";

function UserForm (props) {
    const [submitting, setSubmitting] = useState(false);
    const [inputFields, setInputFields] = useState({
        email: "",
        /*password: "",
        passwordConfirm: "",*/
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        zipcode: "",
        city: "",
        country: ""
    });

    const [errors, setErrors] = useState({
        email: "",
        /*password: "",
        passwordConfirm: "",*/
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        zipcode: "",
        city: "",
        country: ""
    });

    // Logica per la validazione dei campi di input
    const validateValues = (inputValues: { email: string; /*password: string; passwordConfirm: string;*/ firstName?: string; lastName?: string; phone?: string; address?: string; zipcode?: string; city?: string; country?: string; }) => {
        let err = errors
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputValues.email)) {
            err.email = "Email non valida";
        }
        /*if (inputValues.password.length < 5) {
            err.password = "Password troppo corta";
        }
        if (inputValues.passwordConfirm !== inputValues.password) {
            err.passwordConfirm = "Le due password non corrispondono";
        }*/
        return err;
    };

    // Funzione per gestire la modifica dei campi di input
    const switchAndSetErrors = (e: string, value: string) => {
        setErrors({
            ...errors,
            [e]: value
        });
        //console.log(errors)
    };

    const handleChange = (e) => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });
        if (e.target.value !== "") {
            switchAndSetErrors(e.target.name, "");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors(validateValues(inputFields));
        setSubmitting(true);
    };

    useEffect(() => {
        const submitForm = async () => {
            let response;
            if (!Object.values(errors).some(error => error.length !== 0)) {
                const requestData = { ...inputFields, role: props.role }; // Aggiungiamo il ruolo ai dati della richiesta
                response = await userService.register(requestData);
                if (response.status === 201) {
                    props.onSubmit(true, 'Utente creato con successo', 'L\'utente è stato creato con successo.');
                } else if (response.status === 422) {
                    const responseErrors = { ...errors };
                    Object.keys(response.data.errors).forEach(key => {
                        switch (key) {
                            case "first_name":
                                responseErrors.firstName = response.data.errors[key][0];
                                break;
                            case "last_name":
                                responseErrors.lastName = response.data.errors[key][0];
                                break;
                            case "phone_number":
                                responseErrors.phone = response.data.errors[key][0];
                                break;
                            case "zip_code":
                                responseErrors.zipcode = response.data.errors[key][0];
                                break;
                            default:
                                responseErrors[key] = response.data.errors[key][0]
                                break;
                        }
                    })
                    setErrors(responseErrors);
                    setSubmitting(false);
                } else {
                    props.onSubmit(false, 'Errore', 'C\'è stato un problema, ritentare.');
                    setSubmitting(false);
                }
            } else {
                setSubmitting(false);
            }
        };

        if (submitting) {
            submitForm();
        }
    }, [submitting, errors, inputFields]);

    return (
        <Form onSubmit={handleSubmit} noValidate className="inserisciUtenteForm">
            {/* inserire tutti i campi del modulo con relative etichette x*/}
            <MyTextInput name={"email"} label={"Email"} value={inputFields.email} onChange={handleChange} type={"email"} error={errors.email}/>
            {/*<MyTextInput name={"password"} label={"Password"} value={inputFields.password} onChange={handleChange} type={"password"} error={errors.password}/>*/}
            {/*<MyTextInput name={"passwordConfirm"} label={"Conferma password"} value={inputFields.passwordConfirm} onChange={handleChange} type={"password"} error={errors.passwordConfirm}/>*/}
            <MyTextInput name={"firstName"} label={"Nome"} value={inputFields.firstName} onChange={handleChange} type={"text"} error={errors.firstName}/>
            <MyTextInput name={"lastName"} label={"Cognome"} value={inputFields.lastName} onChange={handleChange} type={"text"} error={errors.lastName}/>
            <MyTextInput name={"phone"} label={"Telefono"} value={inputFields.phone} onChange={handleChange} type={"tel"} error={errors.phone}/>
            <MyTextInput name={"address"} label={"Indirizzo"} value={inputFields.address} onChange={handleChange} type={"text"} error={errors.address}/>
            <MyTextInput name={"zipcode"} label={"CAP"} value={inputFields.zipcode} onChange={handleChange} type={"text"} error={errors.zipcode}/>
            <MyTextInput name={"city"} label={"Città"} value={inputFields.city} onChange={handleChange} type={"text"} error={errors.city}/>
            <MyTextInput name={"country"} label={"Nazione"} value={inputFields.country} onChange={handleChange} type={"text"} error={errors.country}/>
            <Button type="submit" className="pulse">Invia</Button>
        </Form>
    );
}

export default UserForm;
