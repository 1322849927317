import React from "react";
import logo from "../../../../logo.svg";
import {ReactComponent as InstagramLogo } from "../../../../img/social-icons/instagram.svg"
import {ReactComponent as FacebookLogo } from "../../../../img/social-icons/Facebook.svg"
import {ReactComponent as WhatsappLogo } from "../../../../img/social-icons/Whatsapp.svg"

function myFooter(){

    let myYear = new Date().getFullYear();
    require("./myFooter.css"); // here
    return(
        <>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 px-3 border-top">
                <div className="col-6 col-md-4 d-flex align-items-center">
                    <a href="/" className="me-2 mb-0 text-body-secondary text-decoration-none lh-1 align-items-center">
                        <img
                            alt="Logo"
                            src={logo}
                            width="30"
                            height="24"
                            className="d-inline-block align-top mb-0"
                        />{' '}
                    </a>
                    <span className="mb-0">© {myYear}</span>
                </div>

                <ul className="nav col-6 col-md-4 justify-content-end list-unstyled d-flex">
                    {/*eslint-disable-next-line*/}
                    <li className="ms-3"><a className="text-body-secondary" href="https://instagram.com/asilomanonellamano?igshid=ZGUzMzM3NWJiOQ==" target="_blank">
                        <InstagramLogo />
                    </a></li>
                    {/*eslint-disable-next-line*/}
                    <li className="ms-3"><a className="text-body-secondary" href="https://www.facebook.com/profile.php?id=100092163435930" target="_blank">
                        <FacebookLogo />
                    </a></li>
                    <li className="ms-3"><a className="text-body-secondary" href="https://wa.me/3456590202">
                        <WhatsappLogo />
                    </a></li>
                </ul>
            </footer>
        </>
    )
}

export default myFooter;