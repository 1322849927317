import React from "react";
import head from "../../../../img/headers/tariffe.jpg";
import Container from "react-bootstrap/Container";

function Tariffe(){
    require("./tariffe.css"); // here
    return(
        <>

            <Container className="pb-3">
            <img src={head} alt="Le tariffe - Mano nella mano, asilo nido a Mariano Comense in provincia di Como" className="img-fluid pb-3"/>

                <h2 className="text-center title">Tariffe</h2>

                <p className="pt-2"><u>Iscrizione annuale non rimborsabile</u>, comprensiva di mantenimento posto e apertura cartella del bambino: € 152,00.</p>

                <div className="table-responsive">
                    <table className="table table-bordered caption-top myTable">
                        <caption>
                            <b>PART-TIME</b><br/> Entrata 7.30-9.30 Uscita 12.30-13.00
                        </caption>
                        <thead>
                            <tr className="">
                                <th scope="col">5 giorni</th>
                                <th scope="col">4 giorni</th>
                                <th scope="col">3 giorni</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>€ 502,00</td>
                                <td>€ 472,00</td>
                                <td>€ 442,00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered caption-top">
                        <caption>
                            <b>FULL-TIME</b><br/> Entrata 7.30-9.30 Uscita 16.00-16.30
                        </caption>
                        <thead>
                        <tr className="">
                            <th scope="col">5 giorni</th>
                            <th scope="col">4 giorni</th>
                            <th scope="col">3 giorni</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>€ 592,00</td>
                            <td>€ 562,00</td>
                            <td>€ 532,00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered caption-top">
                        <caption>
                            <b>POST-NIDO 1</b><br/> Entrata 7.30-9.30 Uscita 16.30-17.30
                        </caption>
                        <thead>
                        <tr className="">
                            <th scope="col">5 giorni</th>
                            <th scope="col">4 giorni</th>
                            <th scope="col">3 giorni</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>€ 622,00</td>
                            <td>€ 592,00</td>
                            <td>€ 562,00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered caption-top">
                        <caption>
                            <b>POST-NIDO 2</b><br/> Entrata 7.30-9.30 Uscita 17.30-18.30
                        </caption>
                        <thead>
                        <tr className="">
                            <th scope="col">5 giorni</th>
                            <th scope="col">4 giorni</th>
                            <th scope="col">3 giorni</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>€ 652,00</td>
                            <td>€ 622,00</td>
                            <td>€ 592,00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered caption-top text-center">
                        <caption>
                            <b>PART-TIME POMERIDIANO 5 giorni</b><br/> Entrata 12.45-13.30
                        </caption>
                        <thead>
                        <tr className="">
                            <th scope="col">Uscita 16.00-16.30</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>€ 392,00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered caption-top text-center">
                        <thead>
                        <tr className="">
                            <th scope="col">Uscita 16.30-17.30</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>€ 417,00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered caption-top text-center">
                        <thead>
                        <tr className="">
                            <th scope="col">Uscita 17.30-18.30</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>€ 442,00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <p>Le rette sono comprensive di:</p>

                <ul className="baloons">
                    <li>Copertura assicurativa;</li>
                    <li>Pannolini e prodotti per l’igiene del bambino ecologici e naturali;</li>
                    <li>Materiale didattico;</li>
                    <li>Pasti;</li>
                    <li>Colloqui trimestrali con le educatrici;</li>
                    <li>Incontri con i professionisti.</li>
                </ul>

            </Container>
        </>
    )
}

export default Tariffe;