import React, {useEffect, useState} from "react";
import teacherService from "../../../../services/teacherService";
import {ChildData, OwnScholarsResponse} from "../../../utilities/constants";
import CurrentDayDiary from "../../../shared/admin/currentDayDiary/currentDayDiary";
import {Accordion} from "react-bootstrap";
import Loader from "../../../shared/admin/loader/loader";


function CompilaRegistro(){
    const [ownScholars, setOwnScholars] = useState<ChildData[]>()
    const [notification, setNotification] = useState<{ type: string; message: string; } | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        // Chiamiamo la funzione per ottenere gli alunni dell'insegnante corrente
        fetchOwnScholars();
    }, []);

    // Definiamo la funzione per ottenere gli alunni dell'insegnante corrente
    const fetchOwnScholars = async () => {
        try {
            // Eseguiamo la chiamata API per ottenere gli alunni dell'insegnante
            const response: OwnScholarsResponse = await teacherService.getChildren({
                paginate: 30,
                sortBy: 'last_name',
                sort: 'DESC',
                searchQuery: '',
                includeInactive: false
            });
            console.log(response.data); // Stampa la risposta della chiamata API
            setOwnScholars(response.data)

        } catch (error) {
            console.error('Errore durante il recupero degli alunni dell\'insegnante:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNotification = (notificationData: React.SetStateAction<{ type: string; message: string; } | null>) => {
        setNotification(notificationData);
        setTimeout(() => {
            setNotification(null); // Nascondi la notifica dopo 5 secondi
        }, 5000);
    };

    return(
        <>
            {loading && <Loader />}
            <Accordion>
                {
                    ownScholars?.map((scholar) => (
                        <CurrentDayDiary
                            eventKey = {scholar.id}
                            title = {scholar.first_name + " " + scholar.last_name}
                            onNotification={handleNotification}
                        >
                        </CurrentDayDiary>
                    ))
                }
            </Accordion>
            {notification && (
                <div className={`alert alert-${notification.type} alert-center`}
                     role="alert"
                     onClick={() => setNotification(null)}
                     style={{ cursor: 'pointer' }}>
                    {notification.message} {/* Renderizza solo il messaggio */}
                </div>
            )}
        </>
    )
}
export default CompilaRegistro;