import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import logo from "../../../../img/logo negative.png";
import authService from "../../../../services/authService";
import {useNavigate} from "react-router-dom";

function MyNavbar(props) {
    let navigate = useNavigate();
    let currentPage = window.location.pathname;

    const logout = async () => {
        await authService.logOut().then(() => {
            //alert("LOGOUT SUCCESSFULL");
            navigate("/login");
            window.location.reload();
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="navbar navbar-expand-lg">
            <Container>
                <Navbar.Brand href="/admin">
                    <img
                        alt="Logo"
                        src={logo}
                        /*width="30"*/
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end">
                    <Nav activeKey = {currentPage}>
                        <Nav.Link href="/admin">{props.user}</Nav.Link>
                        <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )

}

export default MyNavbar;