import {Accordion, Button, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {DiaryData, PostNoteBody} from "../../../utilities/constants";
import childService from "../../../../services/childService";
import Loader from "../loader/loader";

function CurrentDayDiary(props: any){

    const [firstFood, setFirstFood] = useState<number | undefined>();
    const [secondFood, setSecondFood] = useState<number | undefined>();
    const [sideFood, setSideFood] = useState<number | undefined>();
    const [merendaFood, setMerendaFood] = useState<number | undefined>();
    const [feces1Quality, setFeces1Quality] = useState<number | undefined>();
    const [feces2Quality, setFeces2Quality] = useState<number | undefined>();
    const [feces3Quality, setFeces3Quality] = useState<number | undefined>();
    const [milkQuantity, setMilkQuantity] = useState<number | undefined>();
    const [nap1Quality, setNap1Quality] = useState<number | undefined>();
    const [nap2Quality, setNap2Quality] = useState<number | undefined>();
    const [notes, setNotes] = useState<string | undefined>();
    const [activities, setActivities] = useState<string | undefined>();
    const [currentNote, setCurrentNote] = useState<PostNoteBody>();
    const [loading, setLoading] = useState(false);

    const changePrimo = (e) => {
        setFirstFood(parseInt(e.target.value));
    }
    const changeSecondo = (e) => {
        setSecondFood(parseInt(e.target.value));
    }
    const changeContorno = (e) => {
        setSideFood(parseInt(e.target.value));
    }
    const changeMerenda = (e) => {
        setMerendaFood(parseInt(e.target.value));
    }
    const changeFeces1 = (e) => {
        setFeces1Quality(parseInt(e.target.value) > -1 ? parseInt(e.target.value) : undefined);
    }
    const changeFeces2 = (e) => {
        setFeces2Quality(parseInt(e.target.value) > -1 ? parseInt(e.target.value) : undefined);
    }
    const changeFeces3 = (e) => {
        setFeces3Quality(parseInt(e.target.value) > -1 ? parseInt(e.target.value) : undefined);
    }
    const changeMilk = (e) => {
        setMilkQuantity(parseInt(e.target.value) > -1 ? parseInt(e.target.value) : undefined);
    }
    const changeNap1 = (e) => {
        setNap1Quality(parseInt(e.target.value) > -1 ? parseInt(e.target.value) : undefined);
    }
    const changeNap2 = (e) => {
        setNap2Quality(parseInt(e.target.value) > -1 ? parseInt(e.target.value) : undefined);
    }
    const changeNotes = (e) => {
        setNotes(e.target.value);
    }
    const changeActivities = (e) => {
        setActivities(e.target.value);
    }

    useEffect(() => {
        if (firstFood != undefined || secondFood != undefined || sideFood != undefined || merendaFood != undefined || feces1Quality != undefined || feces2Quality != undefined || feces3Quality != undefined || nap1Quality != undefined || nap2Quality != undefined){
            //postDiary(firstFood, secondFood, sideFood, fecesQuality); // TODO ad ogni change mandare la call? o mandarla solo quando premo sul submit? attualmente fa una post e non cancella la nota precedente, quindi mantengo 1 sola call quando schiacciano il bottone, altrimenti si riempie il DB di note. più avanti si potrà pensare di de commentare questa riga per permettere di mandare le richieste ad ogni cambio effettivo
        }
    }, [firstFood, secondFood, sideFood, merendaFood, feces1Quality, feces2Quality, feces3Quality, nap1Quality, nap2Quality])

    useEffect(() => {
        const getDiary = async () => {
            try {
                const response = await childService.getDiary({
                    sortBy: "created_at",
                    sort: "DESC"
                }, props.eventKey);
                console.log(response.data);
                // Filtra gli oggetti per oggi
                const todayEntry = filterDiaryEntriesForToday(response.data);
                if (todayEntry) {
                    setCurrentNote({
                        id: todayEntry.id,
                        notes: todayEntry.notes,
                        activities: todayEntry.activities,
                        food_first_course: todayEntry.food_first_course,
                        food_second_course: todayEntry.food_second_course,
                        food_side: todayEntry.food_side,
                        food_merenda: todayEntry.food_merenda,
                        feces_1_quality: todayEntry.feces_1_quality,
                        feces_2_quality: todayEntry.feces_2_quality,
                        feces_3_quality: todayEntry.feces_3_quality,
                        milk: todayEntry.milk,
                        nap_1_quality: todayEntry.nap_1_quality,
                        nap_2_quality: todayEntry.nap_1_quality,
                        been_read: todayEntry.been_read})
                    setFirstFood(todayEntry.food_first_course);
                    setSecondFood(todayEntry.food_second_course);
                    setSideFood(todayEntry.food_side);
                    setMerendaFood(todayEntry.food_merenda);
                    setFeces1Quality(todayEntry.feces_1_quality);
                    setFeces2Quality(todayEntry.feces_2_quality);
                    setFeces3Quality(todayEntry.feces_3_quality);
                    setMilkQuantity(todayEntry.milk);
                    setNap1Quality(todayEntry.nap_1_quality);
                    setNap2Quality(todayEntry.nap_2_quality);
                    setNotes(todayEntry.notes);
                    setActivities(todayEntry.activities);
                } else {
                    console.log("Nessuna voce per oggi.");
                }
            } catch (error) {
                console.error('Errore durante il recupero del diario del bambino:', error);
            }
        };
        getDiary();
    }, []);

    // Funzione per filtrare gli oggetti per la data `created_at`
    function filterDiaryEntriesForToday(entries: DiaryData[]): DiaryData | null {
        const today = new Date().toISOString().split('T')[0]; // Ottieni la data di oggi nel formato "YYYY-MM-DD"
        const todayEntries = entries.filter(entry => entry.created_at.startsWith(today));

        // Se ci sono voci per oggi, restituisci solo la prima
        if (todayEntries.length > 0) {
            return todayEntries[0];
        } else {
            return null; // Se non ci sono voci per oggi, restituisci null
        }
    }

    const postOrPutDiary = (firstFood?: number, secondFood?: number, sideFood?: number, merendaFood?: number, feces1Quality?: number, feces2Quality?: number, feces3Quality?: number, milk?: number, nap1Quality?: number, nap2Quality?: number, notes?: string, activities?: string) => {
        if (currentNote?.id) {
            console.log("putting data");
            childService.putDiary({notes: notes, activities: activities, food_first_course: firstFood, food_second_course: secondFood, food_side: sideFood, food_merenda: merendaFood, feces_1_quality: feces1Quality, feces_2_quality: feces2Quality, feces_3_quality: feces3Quality, milk: milkQuantity, nap_1_quality: nap1Quality, nap_2_quality: nap2Quality}, currentNote.id)
                .then((response) => {
                    props.onNotification({ type: 'success', message: 'Note aggiornate con successo!' });
                    console.log(response);
                    setLoading(false);
                })
                .catch((error) => {
                    props.onNotification({ type: 'danger', message: `Si è verificato un errore: ${error.response?.data?.message}` });
                    console.log(error.response?.data?.message)
                    setLoading(false);
                })
        } else {
            console.log("posting data");
            childService.postDiary({notes: notes, activities: activities, food_first_course: firstFood, food_second_course: secondFood, food_side: sideFood, food_merenda: merendaFood, feces_1_quality: feces1Quality, feces_2_quality: feces2Quality, feces_3_quality: feces3Quality, milk: milkQuantity, nap_1_quality: nap1Quality, nap_2_quality: nap2Quality}, props.eventKey)
                .then((response) => {
                    setCurrentNote({id: response.data.id});
                    props.onNotification({ type: 'success', message: 'Note inserite con successo!' });
                    console.log(response);
                    setLoading(false);
                })
                .catch((error) => {
                    props.onNotification({ type: 'danger', message: `Si è verificato un errore: ${error.error.response?.data?.message}` });
                    console.log(error.response?.data?.message)
                    setLoading(false);
                })
        }
        return;

    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        postOrPutDiary(firstFood, secondFood, sideFood, merendaFood, feces1Quality, feces2Quality, feces3Quality, milkQuantity, nap1Quality, nap2Quality, notes, activities);
    }

    require("./currentDayDiary.css");
    return(
        <>
            <Accordion.Item eventKey={props.eventKey}>
                {loading && <Loader />}
                <Accordion.Header>{props.title}</Accordion.Header>
                <Accordion.Body>
                    <Form onSubmit={handleSubmit} className="diaryForm">
                        <h3><b className="tertiary">Pasti:</b></h3>
                        <div className="table-responsive">
                            <table className="table">
                                <tr>
                                    <td><b>Primo:</b></td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "primo"} id={props.eventKey + "primo1"} autoComplete="off" value="0" onChange={changePrimo} checked={firstFood === 0} />
                                        <label className="" htmlFor={props.eventKey + "primo1"}>Niente</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "primo"} id={props.eventKey + "primo2"} autoComplete="off" value="1" onChange={changePrimo} checked={firstFood === 1} />
                                        <label className="" htmlFor={props.eventKey + "primo2"}>Poco</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "primo"} id={props.eventKey + "primo3"} autoComplete="off" value="2" onChange={changePrimo} checked={firstFood === 2} />
                                        <label className="" htmlFor={props.eventKey + "primo3"}>Metà</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "primo"} id={props.eventKey + "primo4"} autoComplete="off" value="3" onChange={changePrimo} checked={firstFood === 3} />
                                        <label className="" htmlFor={props.eventKey + "primo4"}>Tutto</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "primo"} id={props.eventKey + "primo5"} autoComplete="off" value="4" onChange={changePrimo} checked={firstFood === 4} />
                                        <label className="" htmlFor={props.eventKey + "primo5"}>Bis</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Secondo:</b></td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "secondo"} id={props.eventKey + "secondo1"} autoComplete="off" value="0" onChange={changeSecondo} checked={secondFood === 0} />
                                        <label className="" htmlFor={props.eventKey + "secondo1"}>Niente</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "secondo"} id={props.eventKey + "secondo2"} autoComplete="off" value="1" onChange={changeSecondo} checked={secondFood === 1} />
                                        <label className="" htmlFor={props.eventKey + "secondo2"}>Poco</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "secondo"} id={props.eventKey + "secondo3"} autoComplete="off" value="2" onChange={changeSecondo} checked={secondFood === 2} />
                                        <label className="" htmlFor={props.eventKey + "secondo3"}>Metà</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "secondo"} id={props.eventKey + "secondo4"} autoComplete="off" value="3" onChange={changeSecondo} checked={secondFood === 3} />
                                        <label className="" htmlFor={props.eventKey + "secondo4"}>Tutto</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "secondo"} id={props.eventKey + "secondo5"} autoComplete="off" value="4" onChange={changeSecondo} checked={secondFood === 4} />
                                        <label className="" htmlFor={props.eventKey + "secondo5"}>Bis</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Contorno:</b></td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "contorno"} id={props.eventKey + "contorno1"} autoComplete="off" value="0" onChange={changeContorno} checked={sideFood === 0} />
                                        <label className="" htmlFor={props.eventKey + "contorno1"}>Niente</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "contorno"} id={props.eventKey + "contorno2"} autoComplete="off" value="1" onChange={changeContorno} checked={sideFood === 1} />
                                        <label className="" htmlFor={props.eventKey + "contorno2"}>Poco</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "contorno"} id={props.eventKey + "contorno3"} autoComplete="off" value="2" onChange={changeContorno} checked={sideFood === 2} />
                                        <label className="" htmlFor={props.eventKey + "contorno3"}>Metà</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "contorno"} id={props.eventKey + "contorno4"} autoComplete="off" value="3" onChange={changeContorno} checked={sideFood === 3} />
                                        <label className="" htmlFor={props.eventKey + "contorno4"}>Tutto</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "contorno"} id={props.eventKey + "contorno5"} autoComplete="off" value="4" onChange={changeContorno} checked={sideFood === 4} />
                                        <label className="" htmlFor={props.eventKey + "contorno5"}>Bis</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Merenda:</b></td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "merenda"} id={props.eventKey + "merenda1"} autoComplete="off" value="0" onChange={changeMerenda} checked={merendaFood === 0} />
                                        <label className="" htmlFor={props.eventKey + "merenda1"}>Niente</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "merenda"} id={props.eventKey + "merenda2"} autoComplete="off" value="1" onChange={changeMerenda} checked={merendaFood === 1} />
                                        <label className="" htmlFor={props.eventKey + "merenda2"}>Poco</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "merenda"} id={props.eventKey + "merenda3"} autoComplete="off" value="2" onChange={changeMerenda} checked={merendaFood === 2} />
                                        <label className="" htmlFor={props.eventKey + "merenda3"}>Metà</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "merenda"} id={props.eventKey + "merenda4"} autoComplete="off" value="3" onChange={changeMerenda} checked={merendaFood === 3} />
                                        <label className="" htmlFor={props.eventKey + "merenda4"}>Tutto</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "merenda"} id={props.eventKey + "merenda5"} autoComplete="off" value="4" onChange={changeMerenda} checked={merendaFood === 4} />
                                        <label className="" htmlFor={props.eventKey + "merenda5"}>Bis</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Latte:</b></td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "latte"} id={props.eventKey + "latte1"} autoComplete="off" value="0" onChange={changeMilk} checked={milkQuantity === 0} />
                                        <label className="" htmlFor={props.eventKey + "latte1"}>Niente</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "latte"} id={props.eventKey + "latte2"} autoComplete="off" value="1" onChange={changeMilk} checked={milkQuantity === 1} />
                                        <label className="" htmlFor={props.eventKey + "latte2"}>Poco</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "latte"} id={props.eventKey + "latte3"} autoComplete="off" value="2" onChange={changeMilk} checked={milkQuantity === 2} />
                                        <label className="" htmlFor={props.eventKey + "latte3"}>Metà</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "latte"} id={props.eventKey + "latte4"} autoComplete="off" value="3" onChange={changeMilk} checked={milkQuantity === 3} />
                                        <label className="" htmlFor={props.eventKey + "latte4"}>Tutto</label>
                                    </td>
                                    <td>
                                        <input type="radio" className="" name={props.eventKey + "latte"} id={props.eventKey + "latte5"} autoComplete="off" value="4" onChange={changeMilk} checked={milkQuantity === 4} />
                                        <label className="" htmlFor={props.eventKey + "latte5"}>Bis</label>
                                    </td>
                                </tr>

                            </table>
                        </div>
                        <div className="d-flex mb-3">
                            <h3 className="d-inline-block"><b className="tertiary">Cacca:</b></h3>
                            <Form.Select className="form-select d-inline-block ms-3" onChange={changeFeces1} value={feces1Quality?.toString()} >
                                <option value="-1">-- seleziona --</option>
                                <option value="0">Liquida</option>
                                <option value="1">Molle</option>
                                <option value="2">Bella</option>
                            </Form.Select>
                        </div>
                        <div className="d-flex mb-3">
                            <h3 className="d-inline-block"><b className="tertiary">Cacca:</b></h3>
                            <Form.Select className="form-select d-inline-block ms-3" onChange={changeFeces2} value={feces2Quality?.toString()} >
                                <option value="-1">-- seleziona --</option>
                                <option value="0">Liquida</option>
                                <option value="1">Molle</option>
                                <option value="2">Bella</option>
                            </Form.Select>
                        </div>
                        <div className="d-flex mb-3">
                            <h3 className="d-inline-block"><b className="tertiary">Cacca:</b></h3>
                            <Form.Select className="form-select d-inline-block ms-3" onChange={changeFeces3} value={feces3Quality?.toString()} >
                                <option value="-1">-- seleziona --</option>
                                <option value="0">Liquida</option>
                                <option value="1">Molle</option>
                                <option value="2">Bella</option>
                            </Form.Select>
                        </div>
                        <div className="d-flex mb-3">
                            <h3 className="d-inline-block"><b className="tertiary">Nanna mattino:</b></h3>
                            <Form.Select className="form-select d-inline-block ms-3" onChange={changeNap1} value={nap1Quality?.toString()} >
                                <option value="-1">-- seleziona --</option>
                                <option value="0">Mezz'ora</option>
                                <option value="1">1 ora</option>
                                <option value="2">1 ora e mezza</option>
                                <option value="3">2 ore</option>
                                <option value="4">2 ore e mezza</option>
                                <option value="5">3 ore</option>
                            </Form.Select>
                        </div>
                        <div className="d-flex mb-3">
                            <h3 className="d-inline-block"><b className="tertiary">Nanna pomeriggio:</b></h3>
                            <Form.Select className="form-select d-inline-block ms-3" onChange={changeNap2} value={nap2Quality?.toString()} >
                                <option value="-1">-- seleziona --</option>
                                <option value="0">Mezz'ora</option>
                                <option value="1">1 ora</option>
                                <option value="2">1 ora e mezza</option>
                                <option value="3">2 ore</option>
                                <option value="4">2 ore e mezza</option>
                                <option value="5">3 ore</option>
                            </Form.Select>
                        </div>
                        <Form.Group className="mb-3" controlId={props.eventKey + "ControlTextarea"}>
                            <h3><b className="tertiary">Attività</b></h3>
                            <Form.Control as="textarea" rows={3} placeholder="Inserisci attività" onChange={changeActivities} value={activities} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId={props.eventKey + "ControlTextarea"}>
                            <h3><b className="tertiary">Note</b></h3>
                            <Form.Control as="textarea" rows={3} placeholder="Inserisci note" onChange={changeNotes} value={notes} />
                        </Form.Group>
                        <Button variant="success" type="submit">
                            Aggiorna note
                        </Button>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>
        </>
    );
}

export default CurrentDayDiary;