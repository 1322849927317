import React, {useEffect, useRef, useState} from "react";
import userService from "../../../../services/userService";
import Form from "react-bootstrap/Form";
import {Eye, EyeSlash} from "react-bootstrap-icons";
import {Button, Container, FloatingLabel, Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

function NewPassword() {
    require("./new-password.css");
    let navigate = useNavigate();
    const [encodedRequestUrl, setEncodedRequestUrl] = useState<string>("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordConfirmationRef = useRef<HTMLInputElement>(null);
    const [selectionStart, setSelectionStart] = useState<number | null>(null);
    const [selectionEnd, setSelectionEnd] = useState<number | null>(null);
    const minimumPasswordLength = 8;
    const [isHovered, setIsHovered] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [pageToNavigate, setPageToNavigate] = useState('');

    const onChangePassword = (e) => {
        setPassword(e.target.value);
        if (error) {
            setError(false);
        }
    }

    const onChangePasswordConfirmation = (e) => {
        setPasswordConfirmation(e.target.value);
        if (error) {
            setError(false);
        }
    };

    const handleFocus = (ref: React.RefObject<HTMLInputElement>) => {
        if (ref.current && selectionStart !== null && selectionEnd !== null) {
            ref.current.setSelectionRange(selectionStart, selectionEnd);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
        if (passwordRef.current) {
            setSelectionStart(passwordRef.current.selectionStart);
            setSelectionEnd(passwordRef.current.selectionEnd);
        }
        setTimeout(() => {
            if (passwordRef.current) {
                passwordRef.current.focus();
            }
        }, 0);
    };

    const togglePasswordConfirmationVisibility = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
        if (passwordConfirmationRef.current) {
            setSelectionStart(passwordConfirmationRef.current.selectionStart);
            setSelectionEnd(passwordConfirmationRef.current.selectionEnd);
        }
        setTimeout(() => {
            if (passwordConfirmationRef.current) {
                passwordConfirmationRef.current.focus();
            }
        }, 0);
    };

    useEffect(() => {
        // Ottieni l'URL corrente
        const urlParams = new URLSearchParams(window.location.search);

        // Estrai il parametro "encodedRequestUrl"
        const encodedUrl = urlParams.get('encodedRequestUrl');

        // Imposta lo stato
        setEncodedRequestUrl(encodedUrl ?? "");

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== passwordConfirmation) {
            setError(true);
            setErrorMessage('Le password non corrispondono');
            return;
        }
        const response = await userService.newPassword(encodedRequestUrl,password, passwordConfirmation);
        if (response.status === 204) {
            setModalTitle('Password aggiornata');
            setModalBody('La password è stata impostata con successo.\n');
            setShowModal(true);
            console.log(response);
            console.log('Password impostata');
            setPageToNavigate('/admin');
        } else if (response.status === 403 || response.status === 404) {
            setModalTitle('Errore nell\'impostazione della password');
            setModalBody(response.data?.message);
            setShowModal(true);
            console.error(response);
            setPageToNavigate('/admin');
        } else if (response.status === 405) {
            setModalTitle('Errore nell\'impostazione della password');
            setModalBody(response.data?.message);
            setShowModal(true);
            console.error(response);
        } else {
            setModalTitle('Errore nell\'impostazione della password');
            setModalBody(response.data?.message);
            setShowModal(true);
            console.error(response);
            setPageToNavigate('/admin');
        }
    }

    const checkError = () => {
        if (password.length < minimumPasswordLength || password !== passwordConfirmation) {
            setErrorMessage('La password deve avere almeno 8 caratteri e le password devono corrispondere.');
            setIsHovered(true);
        }
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
        if (password.length < minimumPasswordLength || password !== passwordConfirmation) {
            setErrorMessage('La password deve avere almeno 8 caratteri e le password devono corrispondere.');
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setErrorMessage(''); // Pulisci il messaggio di errore quando l'hover esce
    };

    const handleCloseModal = () => {
        if (pageToNavigate === "/admin"){
            navigate("/admin");
            window.location.reload();
        }
        setShowModal(false);
    }

    return (
        <>
            <Container fluid>
                <form onSubmit={handleSubmit} className="newPasswordForm">
                    <h3 className="text-center mb-3">Imposta la tua password</h3>
                    <FloatingLabel
                        controlId="floatingPassword"
                        label="Password"
                        className="mb-3"
                    >
                        <Form.Control
                            ref={passwordRef}
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={onChangePassword}
                            onFocus={() => handleFocus(passwordRef)}
                            autoComplete="true"
                        />
                        <button
                            className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
                            type="button"
                            onClick={togglePasswordVisibility}
                            style={{ zIndex: 1, height: "100%" }}
                        >
                            {showPassword ? <EyeSlash /> : <Eye />}
                        </button>
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingPasswordConfirmation"
                        label="Conferma Password"
                        className="mb-3"
                    >
                        <Form.Control
                            ref={passwordConfirmationRef}
                            type={showPasswordConfirmation ? "text" : "password"}
                            value={passwordConfirmation}
                            onChange={onChangePasswordConfirmation}
                            onFocus={() => handleFocus(passwordConfirmationRef)}
                            onBlur={checkError}
                            autoComplete="true"
                        />
                        <button
                            className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
                            type="button"
                            onClick={togglePasswordConfirmationVisibility}
                            style={{ zIndex: 1, height: "100%" }}
                        >
                            {showPasswordConfirmation ? <EyeSlash /> : <Eye />}
                        </button>
                    </FloatingLabel>

                    {isHovered && (password.length < minimumPasswordLength || password !== passwordConfirmation) && (
                        <div className="text-center text-danger">
                            {errorMessage || 'La password deve avere almeno 8 caratteri e le password devono corrispondere.'}
                        </div>
                    )}

                    <button
                        type="submit"
                        className="pulse"
                        disabled={password.length < minimumPasswordLength || password !== passwordConfirmation}
                        onMouseOver={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        Invia
                    </button>
                </form>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBody}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseModal}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default NewPassword;