import React from "react";
import Form from "react-bootstrap/Form";

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
interface MyTextInputInterface {
    name: string;
    label: React.ReactNode;
    type: string;
    value: string | string[] | number;
    onChange?: React.ChangeEventHandler<FormControlElement>;
    controlId?: string;
    error?: string;
    disabled?: boolean
}

const defaultProps: MyTextInputInterface = {
    name: "",
    label: "",
    type: "text",
    value: "",
    error: "",
    disabled: false
}

function MyTextInput(props: MyTextInputInterface){
    require("./myTextInput.css")
    return(
        <>
            <Form.Group controlId={props.controlId} style={{ border: props.error?.length !== 0 ? "1px solid red" : "none" }}>
                <Form.FloatingLabel
                    controlId={props.controlId}
                    label={props.label}>
                    <Form.Control
                        name={props.name}
                        type={props.type}
                        value={props.value}
                        onChange={props.onChange}
                        disabled={props.disabled}
                    />
                </Form.FloatingLabel>
            </Form.Group>
            <Form.Control.Feedback className={props.error && "pb-3 ps-sm-3"} type="invalid">{props.error}</Form.Control.Feedback>
        </>
    )
}
MyTextInput.defaultProps = defaultProps;
export default MyTextInput;
