import axios, {AxiosResponse} from 'axios';
import {OwnScholarsParams, OwnScholarsResponse} from "../components/utilities/constants";

const url = process.env.REACT_APP_API_URL;

export default class teacherService {
    static getOwnScholars(params: OwnScholarsParams): Promise<OwnScholarsResponse> {
        return axios.get(`${url}/ownScholars`, { params }).then((response: AxiosResponse<OwnScholarsResponse>) => {
            return response.data;
        });
    }

    static getChildren(params: OwnScholarsParams): Promise<OwnScholarsResponse> {
        return axios.get(`${url}/children`, {params}).then((response: AxiosResponse<OwnScholarsResponse>) => {
            return response.data;
        });
    }
}