import {Nav} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import userService from "../../../../services/userService";
import {useUser} from "../adminLayout";
import {ChildData, OwnScholarsResponse, UserData, UserInterface, UserRolesEnum} from "../../../utilities/constants";
import MyCardFlip from "../../../shared/admin/myCardFlip/myCardFlip";
import MyTextInput from "../../../shared/default/myTextInput/myTextInput";
import * as Icon from "react-bootstrap-icons";
import MyQuickButton from "../../../shared/admin/myQuickButton/myQuickButton";
import {useWindowSizeAndIconSize} from "../../../utilities/useWindowSizeAndIconSize";
import parentService from "../../../../services/parentService";

function AdminDashboard(){
    require("./admin-dashboard.css");
    const quickButtonsIconSize: string = useWindowSizeAndIconSize();

    // #region Inizio Set User, Role
    const { user, role, loggedUser } = useUser();
    const [userId, setUserId] = useState('');
    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const userInfo = await userService.getUserInfo();
                setUserId(userInfo.id);
            } catch (error) {
                console.error('Errore durante il recupero delle informazioni utente:', error);
            }
        };
        getUserInfo();
    }, []);
    // #endregion

    // #region Inizio Ricerca utente
    const [ searchUser, setSearchUser ] = useState("");
    const [ selectRole, setSelectRole ] = useState(UserRolesEnum.Child);
    const [ timer, setTimer ] = useState<NodeJS.Timeout | null>(null);
    const [ searchResults, setSearchResults ] = useState<UserInterface[]>([]); // Array per memorizzare i risultati della ricerca
    const handleChangeSearchUser = (e) => {
        setSearchUser(e.target.value);
    }
    useEffect(() => {
        const fetchUserBySearch = async () => {
            try {
                // Chiamata a userService passando searchUser e selectRole come parametri
                const response: UserData = await userService.getUserByRoleAndSearchQuery(searchUser, selectRole);
                // Memorizza i risultati della ricerca nello stato
                setSearchResults(response.data);
            } catch (error) {
                console.error('Errore durante il recupero dell\'utente:', error);
                setSearchResults([]); // Se c'è un errore, azzera i risultati della ricerca
            }
        };
        // Effettuiamo la chiamata solo se searchUser non è vuoto
        if (searchUser.trim() !== '') {
            // Se c'è già un timer in esecuzione, lo cancelliamo
            if (timer) {
                clearTimeout(timer);
            }
            // Imposta un nuovo timeout per 400ms prima di eseguire la chiamata API
            const newTimer = setTimeout(fetchUserBySearch, 400);
            setTimer(newTimer);
        } else {
            // Se searchUser è vuoto, azzera i risultati della ricerca
            setSearchResults([]);
        }

        // Pulisce il timeout precedente prima di impostarne uno nuovo
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [searchUser]);
    // #endregion

    // #region Inizio get own children
    const [ownChildren, setOwnChildren] = useState<ChildData[]>();
    useEffect(() => {
        if (role === UserRolesEnum.Parent){
            getOwnChildren();
        }
    }, [role])
    const getOwnChildren = async  () => {
        try {
            const response: OwnScholarsResponse = await parentService.getOwnChildren({
                paginate: 25,
                sortBy: 'last_name',
                sort: 'DESC',
                searchQuery: '',
                includeInactive: false
            });
            console.log(response.data); // Stampa la risposta della chiamata API
            setOwnChildren(response.data)
        } catch (error) {
            console.error('Errore durante il recupero dei children del parent:', error);
        }
    }
    // #endregion

    return(
        <>
            <div className="row">
                {(role === UserRolesEnum.Admin || role === UserRolesEnum.Director) && (
                    <div id="quickButtons" className="col-8 offset-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4 text-center">
                        <MyQuickButton href={"admin/inserisci-utente"} icon={<Icon.PersonAdd size={quickButtonsIconSize}/>} label={"Inserisci utenza"}/>
                    </div>
                )}
                {(role === UserRolesEnum.Parent && ownChildren ? ownChildren.length > 0 : false) && (
                    <div id="quickButtons" className="col-8 offset-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4 text-center">
                        <MyQuickButton href={"/admin/consulta-diario/"} icon={<Icon.PersonHearts size={quickButtonsIconSize}/>} label={"Guarda diario"}/>
                    </div>
                )}
                {(role === UserRolesEnum.Admin || role === UserRolesEnum.Director || role === UserRolesEnum.Teacher || role === UserRolesEnum.Parent) && (
                    <div id="quickButtons" className="col-8 offset-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4 text-center">
                        <MyQuickButton href={"admin/pagina-personale"} icon={<Icon.PencilSquare size={quickButtonsIconSize}/>} label={"Aggiorna profilo"}/>
                    </div>
                )}
                {(role === UserRolesEnum.Teacher || role === UserRolesEnum.Director) && (
                    <div id="quickButtons" className="col-8 offset-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4 text-center">
                        <MyQuickButton href={"admin/compila-registro"} icon={<Icon.VectorPen size={quickButtonsIconSize}/>} label={"Compila registro"}/>
                    </div>
                )}
                {(role === UserRolesEnum.Admin || role === UserRolesEnum.Director || role === UserRolesEnum.Teacher) && (
                    <MyCardFlip
                        flipDirection={"horizontal"}
                        frontDiv={
                            <div id="quickButtons" className="col-8 offset-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4 text-center">
                                <MyQuickButton icon={<Icon.Search size={quickButtonsIconSize}/>}
                                               label={"Cerca utente"}/>
                            </div>
                        }
                        backDiv={
                            <div className="h-100 p-5 border border-tertiary rounded-5">
                                <label htmlFor="selectRole" className="form-label">Seleziona ruolo</label>
                                <select id="selectRole"
                                        value={selectRole}
                                        onChange={(e) => {
                                            setSelectRole(UserRolesEnum[e.target.value]);
                                        }}
                                        className="form-select form-select-sm"
                                        aria-label="Select role of user">
                                    <option value={UserRolesEnum.Teacher}>Insegnante</option>
                                    <option value={UserRolesEnum.Parent}>Genitore</option>
                                    <option value={UserRolesEnum.Child}>Bambin&#601;</option>
                                </select>
                                <br/>
                                <MyTextInput name={"searchUser"} label={"Nome utente"} value={searchUser} onChange={handleChangeSearchUser} controlId={"searchUser"} type={"text"} />
                                {searchResults.length > 0 && selectRole !== UserRolesEnum.Child && (
                                    <div className="list-group">
                                        {searchResults.map((result, index) => (
                                            <Nav.Link href={"/admin/user/" + result.id} className="list-group-item list-group-item-action" key={index}>
                                                {result.first_name} {result.last_name}
                                            </Nav.Link>
                                        ))}
                                    </div>
                                )}
                                {searchResults.length > 0 && selectRole === UserRolesEnum.Child && (
                                    <div className="list-group">
                                        {searchResults.map((result, index) => (
                                            <Nav.Link href={"/admin/child/" + result.id} className="list-group-item list-group-item-action" key={index}>
                                                {result.first_name} {result.last_name}
                                            </Nav.Link>
                                        ))}
                                    </div>
                                )}
                            </div>
                        }
                        returnButton={true}
                    />
                )}
            </div>
        </>
    )
}
export default AdminDashboard;