import axios, {AxiosResponse} from 'axios';

axios.defaults.withCredentials = true;

const url = process.env.REACT_APP_API_URL;

export default class authService {

    static getToken(): Promise<void> {
        try {
            // Esegui la richiesta per ottenere il cookie CSRF
            return axios.get(url + "/sanctum/csrf-cookie", {
                withCredentials: true,
            });
        }
        catch (error) {
            console.log(error)
            return Promise.reject();
        }
    }

    static async login(email: string, password: string): Promise<AxiosResponse<any>> {

        // Esegui la richiesta di login
        return axios.post(url + "/login", {
            email: email,
            password: password
        });
    }

    static async logOut(): Promise<AxiosResponse<any>> {
        return await axios.post(url + "/logout");
    }
}