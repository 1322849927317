import { useUser } from "../adminLayout";
import React, { useEffect, useState } from "react";
import { UserRolesEnum } from "../../../utilities/constants";
import {useNavigate } from "react-router-dom";
import { Button, Container, FloatingLabel, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import UserForm from "../../../shared/admin/myForm/userForm"; // Importiamo il componente figlio
import ChildForm from "../../../shared/admin/myForm/childForm";

function InserisciUtente() {
    require("./inserisci-utente.css");
    let navigate = useNavigate();

    const { user, role, loggedUser } = useUser();
    useEffect(() => {
        if (user.length > 0) {
            if (!loggedUser.isLogged || (role !== UserRolesEnum.Admin && role !== UserRolesEnum.Director)) {
                navigate("/admin");
                window.location.reload();
            }
        }
    }, [loggedUser]);

    const [selectedRole, setSelectedRole] = useState(UserRolesEnum.Director);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');

    const handleSubmit = (success, title, body) => {
        if (success) {
            setModalTitle(title);
            setModalBody(body);
            setShowModal(true);
        } else {
            alert(`${title}: ${body}`);
        }
    };

    const handleCloseModal = () => setShowModal(false);
    const handleGoToHome = () => {
        navigate("/admin");
        window.location.reload();
        setShowModal(false);
    };
    // Funzione per gestire l'inserimento di un nuovo utente
    const handleInsertNewUser = () => {
        setShowModal(false);
        window.location.reload();
    };

    return (
        <>
            <Container className="pb-3">
                <h1 className="mt-3">Ciao {user}</h1>
                <h3 className="mt-3">Inserisci utente</h3>
                <div className="d-flex align-items-center flex-column">
                <div className="myFloatingSelect">
                    <FloatingLabel controlId="role" label="Ruolo">
                        <Form.Select onChange={(e) => setSelectedRole(e.target.value as UserRolesEnum)} value={selectedRole}>
                            <option value={UserRolesEnum.Director}>Direttore</option>
                            <option value={UserRolesEnum.Teacher}>Insegnante</option>
                            <option value={UserRolesEnum.Parent}>Genitore</option>
                            <option value={UserRolesEnum.Child}>Bambino</option>
                        </Form.Select>
                    </FloatingLabel>

                </div>

                </div>
                {selectedRole !== UserRolesEnum.Child && <UserForm onSubmit={handleSubmit} role={selectedRole} />}
                {selectedRole === UserRolesEnum.Child && <ChildForm onSubmit={handleSubmit} role={selectedRole} />}
            </Container>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleInsertNewUser}>
                        Inserisci un nuovo utente
                    </Button>
                    <Button variant="primary" onClick={handleGoToHome}>
                        Torna alla home
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default InserisciUtente;