import React from "react";
import head from "../../../../img/headers/progetto-educativo.jpg";
import Container from "react-bootstrap/Container";

function ProgettoEducativo(){
    require("./progetto-educativo.css"); // here
    return(
        <>
            <Container className="pb-3">

                <img src={head} alt="Il progetto educativo - Mano nella mano, asilo nido a Mariano Comense in provincia di Como" className="img-fluid pb-3"/>

                <h2 className="text-center title">Progetto educativo</h2>

                <p className="pt-2">Il progetto educativo è ispirato alla pedagogia sviluppata da <b>Maria Montessori</b> e dall’approccio educativo di <b>Reggio Children</b>; i quali pongono come cardini la <b>centralità</b>, <b>l’indipendenza</b> e <b>l’autonomia</b> del bambino.</p>

                <hr/>

                <p><b>"Aiutami a fare da solo"</b> è il principio fondamentale del metodo Montessori e dell’asilo nido "Mano nella mano", che ci permette di osservare e riconoscere le competenze del bambino predisponendo attività e stimoli che rispondano alle sue esigenze di crescita e che gli consentano di sviluppare la propria autonomia e, di conseguenza, la sua fiducia in sè stesso.</p>

                <hr/>

                <p>Crediamo nell’importanza dell’<b>educatrice di riferimento</b>, in quanto consente di creare un più forte legame di fiducia nella triade educatrice, bambino e genitore.</p>

            </Container>
        </>
    )
}

export default ProgettoEducativo;