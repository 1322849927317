import React, {useEffect, useState} from "react";
import userService from "../../../../services/userService";
import MyTextInput from "../../default/myTextInput/myTextInput";
import {Button, Form} from "react-bootstrap";
import {UserData, UserInterface, UserRolesEnum} from "../../../utilities/constants";

function ChildForm (props) {
    const [submitting, setSubmitting] = useState(false);
    const [inputFields, setInputFields] = useState({
        first_name: "",
        last_name: "",
        birthday: "",
        parent_1: "",
        parent_2: "",
        teacher_1: "",
        teacher_2: "",
        is_active: true
    });
    const [hiddenFields, setHiddenFields] = useState({
        parent_1: "",
        parent_2: "",
        teacher_1: "",
        teacher_2: ""
    })

    const [errors, setErrors] = useState({
        first_name: "",
        last_name: "",
        birthday: "",
        parent_1: "",
        parent_2: "",
        teacher_1: "",
        teacher_2: ""
    });

    // Funzione per gestire la modifica dei campi di input
    const switchAndSetErrors = (e: string, value: string) => {
        setErrors({
            ...errors,
            [e]: value
        });
        console.log(errors)
    };

    const handleChange = (e) => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });
        console.log(e.target.name)
        if (e.target.value !== "") {
            switchAndSetErrors(e.target.name, "");
        }
    };

    const handleSubmit = async (event) => {
        console.log(inputFields);
        event.preventDefault();
        setSubmitting(true);
    };

    useEffect(() => {
        const submitForm = async () => {
            let response;
            if (!Object.values(errors).some(error => error.length !== 0)) {
                response = await userService.storeChild(inputFields, hiddenFields);
                if (response.status === 201) {
                    props.onSubmit(true, 'Bambino creato con successo', 'Il bambino è stato creato con successo.');
                } else if (response.status === 422) {
                    const responseErrors = { ...errors };
                    Object.keys(response.data.errors).forEach(key => {
                        responseErrors[key] = response.data.errors[key][0]
                    })
                    setErrors(responseErrors);
                    setSubmitting(false);
                } else {
                    props.onSubmit(false, 'Errore', 'C\'è stato un problema, ritentare.');
                    setSubmitting(false);
                }
            } else {
                setSubmitting(false);
            }
        };

        if (submitting) {
            submitForm();
        }
    }, [submitting, errors, inputFields]);

    const [ timer, setTimer ] = useState<NodeJS.Timeout | null>(null);
    const [searchingInput, setSearchingInput] = useState('');
    const [ searchResults, setSearchResults ] = useState<UserInterface[]>([]); // Array per memorizzare i risultati della ricerca
    const handleChangeSearchUser = (e) => {
        const inputValue = e.target.value.trim();
        if (e.target.value !== "") {
            switchAndSetErrors(e.target.name, "");
        }
        setSearchingInput(e.target.name);
        setInputFields({ ...inputFields, [e.target.name]: inputValue });
        setHiddenFields({...hiddenFields, [e.target.name]: "" })

        // Se c'è già un timer in esecuzione, lo cancelliamo
        if (timer) {
            clearTimeout(timer);
        }

        // Imposta un nuovo timer per eseguire la ricerca dopo un breve ritardo
        const newTimer = setTimeout(() => {
            const fetchUserBySearch = async (value) => {
                try {
                    // Chiamata a userService passando searchUser e selectRole come parametri
                    let role: UserRolesEnum = UserRolesEnum.Parent;
                    switch (e.target.name) {
                        case "parent_1":
                        case "parent_2":
                            role = UserRolesEnum.Parent;
                            break;
                        case "teacher_1":
                        case "teacher_2":
                            role = UserRolesEnum.Teacher;
                            break;
                    }
                    const response: UserData = await userService.getUserByRoleAndSearchQuery(value, role);
                    // Memorizza i risultati della ricerca nello stato
                    setSearchResults(response.data);
                    //console.log(response.data)
                } catch (error) {
                    console.error('Errore durante il recupero dell\'utente:', error);
                    setSearchResults([]); // Se c'è un errore, azzera i risultati della ricerca
                }
            };

            // Effettuiamo la chiamata solo se searchUser non è vuoto
            if (inputValue !== '') {
                    fetchUserBySearch(inputValue);
            } else {
                // Se searchUser è vuoto, azzera i risultati della ricerca
                setSearchResults([]);
            }

        }, 400);

        // Memorizza il nuovo timer
        setTimer(newTimer);

        // Pulisce il timeout precedente prima di impostarne uno nuovo
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }

    const selectItem = (event, id, name) => {
        setInputFields({ ...inputFields, [name]: event.target.innerText });
        setHiddenFields({...hiddenFields, [name]: id});
        setSearchResults([]);
    }

    return (
        <Form onSubmit={handleSubmit} noValidate className="inserisciUtenteForm">
            {/* inserire tutti i campi del modulo con relative etichette x*/}
            <MyTextInput name={"first_name"} label={"Nome"} value={inputFields.first_name} onChange={handleChange} type={"text"} error={errors.first_name}/>
            <MyTextInput name={"last_name"} label={"Cognome"} value={inputFields.last_name} onChange={handleChange} type={"text"} error={errors.last_name}/>
            <div className="myFloatingDate">
                <MyTextInput name={"birthday"} label={"Compleanno"} value={inputFields.birthday} onChange={handleChange} type={"date"} error={errors.birthday}/>
            </div>
            <MyTextInput name={"parent_1"} label={"Genitore 1"} value={inputFields.parent_1} onChange={handleChangeSearchUser} type={"text"} error={errors.parent_1}/>
            {searchResults.length > 0 && searchingInput === "parent_1" && (
                <ul className="list-group">
                    {searchResults.map((result, index) => (
                        <li key={index} value={result.id} className="list-group-item list-group-item-action" onClick={(event) => selectItem(event, result.id, "parent_1")}>
                            {result.first_name} {result.last_name}
                        </li>
                    ))}
                </ul>
            )}
            <MyTextInput name={"parent_2"} label={"Genitore 2"} value={inputFields.parent_2} onChange={handleChangeSearchUser} type={"text"} error={errors.parent_2}/>
            {searchResults.length > 0 && searchingInput === "parent_2" && (
                <ul className="list-group">
                    {searchResults.map((result, index) => (
                        <li key={index} value={result.id} className="list-group-item list-group-item-action" onClick={(event) => selectItem(event, result.id, "parent_2")}>
                            {result.first_name} {result.last_name}
                        </li>
                    ))}
                </ul>
            )}
            <MyTextInput name={"teacher_1"} label={"Teacher 1"} value={inputFields.teacher_1} onChange={handleChangeSearchUser} type={"text"} error={errors.teacher_1}/>
            {searchResults.length > 0 && searchingInput === "teacher_1" && (
                <ul className="list-group">
                    {searchResults.map((result, index) => (
                        <li key={index} value={result.id} className="list-group-item list-group-item-action" onClick={(event) => selectItem(event, result.id, "teacher_1")}>
                            {result.first_name} {result.last_name}
                        </li>
                    ))}
                </ul>
            )}
            <MyTextInput name={"teacher_2"} label={"Teacher 2"} value={inputFields.teacher_2} onChange={handleChangeSearchUser} type={"text"} error={errors.teacher_2}/>
            {searchResults.length > 0 && searchingInput === "teacher_2" && (
                <ul className="list-group">
                    {searchResults.map((result, index) => (
                        <li key={index} value={result.id} className="list-group-item list-group-item-action" onClick={(event) => selectItem(event, result.id, "teacher_2")}>
                            {result.first_name} {result.last_name}
                        </li>
                    ))}
                </ul>
            )}
            <Button type="submit" className="pulse">Invia</Button>
        </Form>
    )
}
export default ChildForm;