import React, { useState, useEffect } from 'react';

const InstallBanner: React.FC = () => {
    const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handler = (e: Event) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setIsVisible(true);
        };

        window.addEventListener('beforeinstallprompt', handler);

        return () => {
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);

    const handleInstallClick = async () => {
        if (!deferredPrompt) return;

        const promptEvent = deferredPrompt as any;
        promptEvent.prompt();

        const choiceResult = await promptEvent.userChoice;
        if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
        } else {
            console.log('User dismissed the A2HS prompt');
        }

        setDeferredPrompt(null);
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div style={{ position: 'fixed', bottom: '10px', left: '10px', backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', zIndex: 1000 }}>
            <p>Installa la nostra app per un'esperienza migliore.</p>
            <button onClick={handleInstallClick}>Installa</button>
        </div>
    );
};

export default InstallBanner;
