import React from "react";
import head from "../../../../img/headers/gli-spazi.jpg";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox"
import { images } from "./images";

function GliSpazi(){
    const [index, setIndex] = useState(-1);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index: number) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    return(
        <>

            <Container className="pb-3">

                <img src={head} alt="Il progetto educativo - Mano nella mano, asilo nido a Mariano Comense in provincia di Como" className="img-fluid pb-3"/>

                <h2 className="text-center title">Gli spazi</h2>

                <p className="pt-2">Gli spazi sono stati pensati per rispondere ai bisogni dei bambini e delle educatrici cercando di renderli stimolanti e funzionali.</p>

                <p>Sono presenti:</p>

                <ul className="baloons">
                    <li>Una zona riposo destinata anche ad attività di movimento;</li>
                    <li>Zona riservata ai lattanti (dai 3 mesi ai 12 mesi circa);</li>
                    <li>Servizi igienici;</li>
                    <li>Spazio per il pranzo;</li>
                    <li>Uno spazio dedicato ai mezzani e grandi suddiviso in varie zone di gioco per lo svolgimento di
                        vari tipi di attività;</li>
                    <li>Un cortile esterno.</li>
                </ul>

                <div>
                    <Gallery
                        images={images}
                        onClick={handleClick}
                        enableImageSelection={false}
                    />
                    {!!currentImage && (
                        /* @ts-ignore */
                        <Lightbox
                            mainSrc={currentImage.original}
                            imageTitle={currentImage.caption}
                            mainSrcThumbnail={currentImage.src}
                            nextSrc={nextImage.original}
                            nextSrcThumbnail={nextImage.src}
                            prevSrc={prevImage.original}
                            prevSrcThumbnail={prevImage.src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                        />
                    )}
                </div>

            </Container>
        </>
    )
}

export default GliSpazi;