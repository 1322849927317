import ReactCardFlip from "react-card-flip";
import React, {ReactNode, useState} from "react";
import  * as Icon from 'react-bootstrap-icons';

interface MyCardFlipInterface {
    flipDirection: "horizontal" | "vertical" | undefined;
    frontDiv: ReactNode;
    backDiv: ReactNode;
    returnButton: boolean;
}

function MyCardFlip (props: MyCardFlipInterface){

    const [isFlipped, setIsFlipped] = useState(false);
    const handleClick = () => {
        setIsFlipped(!isFlipped);
    };

    return(
        <div style={{ height: '100%', overflow: 'hidden' }}>
            <ReactCardFlip
                isFlipped={isFlipped}
                flipDirection={props.flipDirection}
            >
                <div className="front col-12 p-3" onClick={handleClick} style={{ cursor: "pointer" }}>
                    { props.frontDiv }
                </div>

                <div className="back col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4 p-3" onClick={  !props.returnButton ? handleClick : undefined} >
                    <Icon.ArrowCounterclockwise size={"10%"} className="pe-3 pt-3 float-end fw-bolder"  fill={"var(--black)"} onClick={  props.returnButton ? handleClick : undefined} />
                    { props.backDiv }
                </div>
            </ReactCardFlip>
        </div>
    )
}
export default MyCardFlip;