import React from 'react';
import Container from "react-bootstrap/Container";
import head from "../../../img/headers/404.png";

function N404() {

    return (
        <>
            <Container className="py-3 text-center">
                <h1>Oops... Non abbiamo trovato quello che stavi cercando</h1>

                <img src={head} alt="Pagina non trovata" className="img-fluid pb-3"
                     style={{
                         maxHeight: "70vh"
                     }}/>

            </Container>
        </>
    );
}

export default N404;