import React from 'react';

function Loader() {
    return (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Caricamento...</span>
            </div>
        </div>
    );
}

export default Loader;
