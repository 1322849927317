import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import slider1 from "../../../../img/headers/slider 1.jpg";
import slider2 from "../../../../img/headers/slider 2.jpg";
import angoloMorbido from "../../../../img/spazi/angolo morbido 4.jpg";
import areaLattanti from "../../../../img/spazi/area lattanti 4.jpg";
import zonaGiocoSimbolico from "../../../../img/spazi/zona gioco simbolico.jpg";
import zonaPastoEGrandeCostruttivita from "../../../../img/spazi/zona pasto e grande costruttivita.jpg";
import zonaPranzoPiccoli from "../../../../img/spazi/zona pranzo piccoli 1.jpg";


function MySlider() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    return (
        <AutoplaySlider
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={6000}
        >
            <div data-src={slider1} />
            <div data-src={slider2} />
            <div data-src={angoloMorbido} />
            <div data-src={areaLattanti} />
            <div data-src={zonaGiocoSimbolico} />
            <div data-src={zonaPastoEGrandeCostruttivita} />
            <div data-src={zonaPranzoPiccoli} />
        </AutoplaySlider>
    );
}
export default MySlider;