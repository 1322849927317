import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import logo from "../../../../img/logo negative.png";

function MyNavbar() {
    let currentPage = window.location.pathname;

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="navbar navbar-expand-lg">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt="Logo"
                        src={logo}
                        /*width="30"*/
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end">
                    <Nav activeKey = {currentPage}>
                        <Nav.Link href="/chi-siamo">Chi siamo</Nav.Link>
                        <Nav.Link href="/progetto-educativo">Progetto educativo</Nav.Link>
                        <Nav.Link href="/gli-spazi">Gli spazi</Nav.Link>
                        <Nav.Link href="/tariffe">Tariffe</Nav.Link>
                        <Nav.Link href="/inserimento">Inserimento</Nav.Link>
                        {/*<Nav.Link href="/gallery">Gallery</Nav.Link>*/}
                        <Nav.Link href="/contatti">Contatti</Nav.Link>
                        <Nav.Link href="/login">Login</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MyNavbar;