import axios, {AxiosResponse} from 'axios';
import {
    ChildData,
    ChildFieldsInterface, ChildHiddenFieldsInterface,
    InputFieldsInterface,
    isLoggedUser, UserData,
    UserInterface,
    UserRolesEnum
} from "../components/utilities/constants";

axios.defaults.withCredentials = true;

const url = process.env.REACT_APP_API_URL;

export default class userService {

    static isLogged(): Promise<isLoggedUser> {
        return axios.get(`${url}/user/isLogged`).then((response: AxiosResponse<any>) => {
            return response.data;
        });
    }

    static getUserInfo(): Promise<UserInterface> {
        return axios.get(`${url}/user`).then((response: AxiosResponse<any>) => {
            return response.data;
        });
    }

    static register(inputFields: InputFieldsInterface): Promise<any> {
        return axios.post(`${url}/register`, {
            email: inputFields.email,
            /*password: inputFields.password,
            password_confirmation: inputFields.passwordConfirm,*/
            role: inputFields.role,
            first_name: inputFields.firstName,
            last_name: inputFields.lastName,
            phone_number: inputFields.phone,
            address: inputFields.address,
            zip_code: inputFields.zipcode,
            city: inputFields.city,
            country: inputFields.country
        }).then((response: AxiosResponse<any>) => {
            return response;
        }).catch((error: any) => {
            return error.response;
        });
    }

    static storeChild(inputFields: ChildFieldsInterface, hiddenFields: ChildHiddenFieldsInterface): Promise<any> {
        return axios.post(`${url}/child`, {
            first_name: inputFields.first_name,
            last_name: inputFields.last_name,
            birthday: inputFields.birthday,
            parent_1: hiddenFields.parent_1,
            parent_2: hiddenFields.parent_2 !== "" ? hiddenFields.parent_2 : undefined,
            teacher_1: hiddenFields.teacher_1,
            teacher_2: hiddenFields.teacher_2 !== "" ? hiddenFields.teacher_2 : undefined,
            is_active: true
        }).then((response: AxiosResponse<any>) => {
            return response;
        }).catch((error: any) => {
            return error.response;
        });
    }

    static getUserByRoleAndSearchQuery(userId: string, role: UserRolesEnum): Promise<UserData> {
        let urlRole;
        switch (role) {
            case UserRolesEnum.Child:
                urlRole = "children";
                break;
            case UserRolesEnum.Parent:
                urlRole = "parents";
                break;
            case UserRolesEnum.Teacher:
                urlRole = "teachers";
                break;
        }
        if (urlRole === ""){
            throw new Error("Ruolo non riconosciuto");
        }
        return axios.get(`${url}/${urlRole}?searchQuery=${userId}&includeInactive=true`).then((response: AxiosResponse<UserData>) => {
            return response.data;
        }).catch((error: any) => {
            return error.response;
        });
    }

    static getUser(userId?: string): Promise<UserInterface> {
        const requestUrl = userId ? `${url}/user/${userId}` : `${url}/user`;
        return axios.get(requestUrl)
            .then((response: AxiosResponse<UserInterface>) => {
                return response;
            })
            .catch((error: any) => {
                return error.response;
            });
    }

    static getChild(childId?: string): Promise<ChildData> {
        return axios.get(`${url}/child/${childId}`)
            .then((response: AxiosResponse<ChildData>) => {
                return response;
            })
            .catch((error: any) => {
                return error.response;
            });
    }

    static updateUser(userId: string, userData: Partial<UserInterface>): Promise<UserInterface> {
        return axios.put(`${url}/user/${userId}`, userData)
            .then((response: AxiosResponse<UserInterface>) => {
                return response.data;
            })
            .catch((error: any) => {
                throw error.response;
            })
    }

    static deleteUser(userId: string): Promise<any> {
        return axios.delete(`${url}/user/${userId}`)
            .then((response: AxiosResponse<any>) => {
                return response
            }).catch((error: any) => {
                return error.response;
            });
    }

    static updateChild(childId: string, childData: Partial<ChildData>): Promise<ChildData> {
        return axios.put(`${url}/child/${childId}`, childData)
            .then((response: AxiosResponse<ChildData>) => {
                return response.data;
            })
            .catch((error: any) => {
                throw error.response;
            })
    }

    static newPassword(url: string, password: string, passwordConfirmation: string): Promise<any> {
        return axios.post(url, {
            password: password,
            password_confirmation: passwordConfirmation
        }).then((response: AxiosResponse<any>) => {
            return response;
        }).catch((error: any) => {
            return error.response;
        });
    }

    static resetPassword(email:string): Promise<any> {
        return axios.post(`${url}/resetPassword`, {
            email: email
        }).then((response: AxiosResponse<any>) => {
            return response;
        }).catch((error: any) => {
            return error.response;
        });
    }

}
