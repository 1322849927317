import React from "react";
import {Outlet} from "react-router-dom";
import MyNavbar from "../../shared/default/myNavbar/myNavbar";
import MyFooter from "../../shared/default/myFooter/myFooter";

const Layout = () => {
    return (
        <div className="app">
            <MyNavbar />
            <div className="content">
                <Outlet />
            </div>
            <MyFooter />
        </div>
    );
};

export default Layout;