import React, {Fragment, ReactNode, useEffect, useState} from 'react';
import head from "../../../../img/headers/inserimento.jpg";
import Container from "react-bootstrap/Container";

function Inserimento() {

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isSmaller992: boolean = width < 992;
    
    let delimiter: HTMLElement | ReactNode;

    if (isSmaller992){
        delimiter = <br/>
    }
    else{
        delimiter = <Fragment>&emsp;</Fragment>
    }

    return(
        <>
            <Container className="pb-3">

                <img src={head} alt="Inserimento - Mano nella mano, asilo nido a Mariano Comense in provincia di Como" className="img-fluid pb-3"/>

                <h2 className="text-center title">Inserimento</h2>

                <p className="pt-2">L’<b>ingresso</b> del <b>bambino</b> al nido è un processo che non coinvolge solo il bambino, ma anche il <b>genitore</b> e le <b>educatrici</b>.</p>

                <hr/>

                <p>Durante questa fase l’<b>educatrice</b> si mette a <b>disposizione</b> del bambino, <b>accogliendolo</b> e adattando l’ambiente alle sue <b>necessità</b>.</p>

                <hr/>

                <p>Il <b>periodo di inserimento</b> avverrà gradualmente ed è articolato su <b>due settimane</b> durante le quali il genitore, dapprima presente in struttura,si allontanerà per periodi sempre più lunghi consentendo al bambino e all’educatrice di instaurare una <b>relazione di fiducia</b>.</p>

                <hr/>

                <p>Al momento dell’iscrizione verrà consegnato un <b>calendario</b> indicativo ,che potrà subire dei cambiamenti in funzione dello stato di <b>benessere del bambino e del genitore</b>.</p>

                <hr/>

                <div className="table-responsive">
                    <table className="table table-bordered caption-top">
                        <caption>
                            <b>CALENDARIO FULL-TIME</b>
                        </caption>
                        <thead>
                        <tr className="">
                            <th scope="col">I SETTIMANA</th>
                            <th scope="col">II SETTIMANA</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><b>Giorno 1</b>{delimiter} 9.30-11.00 {delimiter} con il genitore</td>
                            <td><b>Giorno 1</b>{delimiter} 9.00-11.00 {delimiter} distacco all'ingresso</td>
                        </tr>
                        <tr>
                            <td><b>Giorno 2</b>{delimiter} 9.30-11.00 {delimiter} distacco ore 10.30</td>
                            <td><b>Giorno 2</b>{delimiter} 9.00-12.30 {delimiter} con pranzo</td>
                        </tr>
                        <tr>
                            <td><b>Giorno 3</b>{delimiter} 9.30-11.00 {delimiter} distacco ore 10.15</td>
                            <td><b>Giorno 3</b>{delimiter} 8.30-13.00 {delimiter} con pranzo</td>
                        </tr>
                        <tr>
                            <td><b>Giorno 4</b>{delimiter} 9.30-11.00 {delimiter} distacco ore 10.00</td>
                            <td><b>Giorno 4</b>{delimiter} 8.30-16.00 {delimiter} con nanna e merenda</td>
                        </tr>
                        <tr>
                            <td><b>Giorno 5</b>{delimiter} 9.00-11.00 {delimiter} distacco all'ingresso</td>
                            <td><b>Giorno 5</b>{delimiter} orario previsto dalla retta</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered caption-top">
                        <caption>
                            <b>CALENDARIO PART-TIME</b>
                        </caption>
                        <thead>
                        <tr className="">
                            <th scope="col">I SETTIMANA</th>
                            <th scope="col">II SETTIMANA</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><b>Giorno 1</b>{delimiter} 9.30-11.00 {delimiter} con il genitore</td>
                            <td><b>Giorno 1</b>{delimiter} 9.00-11.00 {delimiter} distacco all'ingresso</td>
                        </tr>
                        <tr>
                            <td><b>Giorno 2</b>{delimiter} 9.30-11.00 {delimiter} distacco ore 10.30</td>
                            <td><b>Giorno 2</b>{delimiter} 9.00-12.30 {delimiter} con pranzo</td>
                        </tr>
                        <tr>
                            <td><b>Giorno 3</b>{delimiter} 9.30-11.00 {delimiter} distacco ore 10.15</td>
                            <td><b>Giorno 3</b>{delimiter} 8.30-12.30 {delimiter} con pranzo</td>
                        </tr>
                        <tr>
                            <td><b>Giorno 4</b>{delimiter} 9.30-11.00 {delimiter} distacco ore 10.00</td>
                            <td><b>Giorno 4</b>{delimiter} 8.30-13.00 {delimiter} con pranzo</td>
                        </tr>
                        <tr>
                            <td><b>Giorno 5</b>{delimiter} 9.00-11.00 {delimiter} distacco all'ingresso</td>
                            <td><b>Giorno 5</b>{delimiter} orario previsto dalla retta</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </Container>
        </>
    );
}

export default Inserimento;