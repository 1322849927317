import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from "./components/application/default/layout";
import Home from "./components/application/default/home/home";
import ChiSiamo from "./components/application/default/chi-siamo/chi-siamo";
import ProgettoEducativo from "./components/application/default/progetto-educativo/progetto-educativo";
import GliSpazi from "./components/application/default/gli-spazi/gli-spazi";
import Tariffe from "./components/application/default/tariffe/tariffe";
import Inserimento from "./components/application/default/inserimento/inserimento";
import Contatti from "./components/application/default/contatti/contatti";
import Login from "./components/application/default/login/login";
import AdminLayout from "./components/application/admin/adminLayout";
import AdminDashboard from "./components/application/admin/admin-dashboard/admin-dashboard";
import CompilaRegistro from "./components/application/admin/compila-registro/compila-registro";
import N404 from "./components/application/404/404";
import 'react-18-image-lightbox/style.css';
import User from "./components/application/admin/user/user";
import InserisciUtente from "./components/application/admin/inserisci-utente/inserisci-utente";
import ConsultaDiario from "./components/application/admin/consulta-diario/consulta-diario";
import InstallBanner from "./components/shared/default/installBanner/install-banner";
import ForgotPassword from "./components/application/default/forgot-password/forgot-password";
import NewPassword from "./components/application/admin/new-password/new-password";

export default function App() {
    return (
        <BrowserRouter>
            <InstallBanner />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="chi-siamo" element={<ChiSiamo />} />
                    <Route path="progetto-educativo" element={<ProgettoEducativo />} />
                    <Route path="gli-spazi" element={<GliSpazi />} />
                    <Route path="tariffe" element={<Tariffe />} />
                    <Route path="inserimento" element={<Inserimento />} />
                    <Route path="contatti" element={<Contatti />} />
                    <Route path="login" element={<Login />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="*" element={<N404 />} />
                </Route>
                <Route path="admin" element={<AdminLayout />} >
                    <Route index element={<AdminDashboard />} />
                    <Route path="inserisci-utente" element={<InserisciUtente />} />
                    <Route path="pagina-personale" element={<User />} />
                    <Route path="compila-registro" element={<CompilaRegistro />} />
                    <Route path="user/:userId" element={<User />} />
                    <Route path="child/:userId" element={<User />} />
                    <Route path="consulta-diario" element={<ConsultaDiario />} />
                    <Route path="new-password" element={<NewPassword />} />
                    <Route path="reset-password" element={<NewPassword />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}