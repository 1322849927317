import React from 'react';
import MySlider from "../../../shared/default/mySlider/mySlider";
import {Container} from "react-bootstrap";
import  * as Icon from 'react-bootstrap-icons';
import head from "../../../../img/logo.png";
import {useWindowSizeAndIconSize} from "../../../utilities/useWindowSizeAndIconSize";
function Home() {
    require("./home.css")

    const quickButtonsIconSize: string = useWindowSizeAndIconSize();

    return (
        <>
            <Container className="text-center">
                <img src={head} alt="Logo - Mano nella mano, asilo nido a Mariano Comense in provincia di Como" className="img-fluid py-3"/>
            </Container>
            <Container>
                <MySlider/>
                <div className="table-responsive pt-3">
                    <table id="quickButtons" className="table text-center">
                            <tr>
                                <td className="py-3">
                                    <a href="chi-siamo">
                                        <Icon.HouseDoor size={quickButtonsIconSize}/><br/>
                                        Chi siamo
                                    </a>
                                </td>
                                <td className="py-3">
                                    <a href="progetto-educativo">
                                        <Icon.Clipboard2Heart size={quickButtonsIconSize}/><br/>
                                        Progetto educativo
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className="py-3">
                                    <a href="tariffe">
                                        <Icon.PiggyBank size={quickButtonsIconSize}/><br/>
                                        Tariffe
                                    </a>
                                </td>
                                <td className="py-3">
                                    <a href="contatti">
                                        <Icon.InfoCircle size={quickButtonsIconSize}/><br/>
                                        Contatti
                                    </a>
                                </td>
                            </tr>
                    </table>
                </div>
            </Container>
        </>
    );
}

export default Home;