import React from "react";
import head from "../../../../img/headers/chi-siamo.jpg"
import Container from "react-bootstrap/Container";


function ChiSiamo() {
    require("./chi-siamo.css"); // here
    return (
        <>

            <Container className="pb-3">

                <img src={head} alt="Chi siamo - Mano nella mano, asilo nido a Mariano Comense in provincia di Como" className="img-fluid pb-3"/>

                <h2 className="text-center title">Chi siamo</h2>

                <p className="pt-2">L’asilo nido <b>Mano nella mano</b> nasce dalla volontà della sua coordinatrice <b>Cinzia Alimanni</b> di creare una realtà accogliente e a misura di <b>bambino</b> che potesse accompagnare quest’ultimo nel proprio individuale percorso di crescita.</p>

                <hr/>

                <p>Lo <b>scopo</b> che ci poniamo è quello, non solo di <b>guidare</b> il bambino nel suo <b>sviluppo</b>, ma anche quello di <b>affiancare e sostenere i genitori</b> nel difficile cammino della genitorialità. È proprio per trasmettere questo messaggio che abbiamo scelto “Mano nella mano” come nome dell’asilo.</p>

                <hr/>

                <p>L’asilo nido Mano nella mano è un <b>luogo</b> in cui ogni spazio è stato pensato per <b>rispondere ai bisogni</b> di ogni bambino, da quello più piccolo al più grandicello.</p>

                <hr/>

                <p>Il <b>nido</b> si distingue per la <b>cura</b> dei suoi <b>ambienti</b> attraversati dalla luce naturale,per mezzo delle sue ampie vetrate , i colori neutri e tenui , i pavimenti e gli arredi curati rendono i <b>luoghi molto caldi e accoglienti</b>.</p>

                <hr/>

                <p>L’asilo può ospitare <b>25 bambini</b> di età compresa <b>dai 3 mesi ai 3 anni</b>.</p>

            </Container>

        </>
    );
}
export default ChiSiamo;