import React, { useState, useRef } from 'react';
import {FloatingLabel, Nav} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import authService from "../../../../services/authService";
import { useNavigate } from 'react-router-dom';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

function Login() {
    require("./login.css");
    let navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const passwordRef = useRef<HTMLInputElement>(null);
    const [selectionStart, setSelectionStart] = useState<number | null>(null);
    const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
        if (passwordRef.current) {
            setSelectionStart(passwordRef.current.selectionStart);
            setSelectionEnd(passwordRef.current.selectionEnd);
        }
        setTimeout(() => {
            if (passwordRef.current) {
                passwordRef.current.focus();
            }
        }, 0);
    };

    const handleFocus = () => {
        if (passwordRef.current && selectionStart !== null && selectionEnd !== null) {
            passwordRef.current.setSelectionRange(selectionStart, selectionEnd);
        }
    };

    const login = () => {
        authService.getToken().then(() => {
            authService.login(email, password).then((response) => {
                console.log(response);
                navigate("/admin");
                window.location.reload();
                console.log("LOGIN SUCCESSFULL");
            }).catch((error) => {
                if(error?.response?.status === 422) {
                    console.error(error);
                    setPassword("");
                    setErrorMessage("e-mail o password errate");
                    setError(true);
                }
                else {
                    setErrorMessage("Errore imprevisto\nprova a ricaricare la pagina");
                    setError(true);
                    console.error(error);
                }
            })
        }).catch((error) => {
            setErrorMessage("Errore imprevisto\nprova a ricaricare la pagina");
            setError(true);
            console.error(error);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email !== "" && password !== "") {
            login();
        }
    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
        if (error) {
            setError(false);
        }
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value);
        if (error) {
            setError(false);
        }
    }

    const minimumPasswordLength = 5;

    return (
        <Container fluid>
            <form onSubmit={handleSubmit} className="loginForm">
                <FloatingLabel
                    controlId="floatingInput"
                    label="Email"
                    className="mb-3"
                >
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={onChangeEmail}
                        autoComplete="true"
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingPassword"
                    label="Password"
                    className="mb-3"
                >
                    <Form.Control
                        ref={passwordRef}
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={onChangePassword}
                        onFocus={handleFocus}
                        autoComplete="true"
                    />
                    <button
                        className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{ zIndex: 1, height: "100%" }}
                    >
                        {showPassword ? <EyeSlash /> : <Eye />}
                    </button>
                </FloatingLabel>
                {error &&
                    <div className="invalid-feedback text-center mb-2">
                        <label className="text-decoration-underline">{errorMessage.split('\n').map((line, index) => <div key={index}>{line}</div>)}</label>
                    </div>
                }
                <button type="submit" className="pulse" disabled={email.length === 0 || password.length < minimumPasswordLength}>Login</button>
                <Nav.Link href="/forgot-password" className="text-decoration-underline tertiary">password dimenticata?</Nav.Link>
            </form>
        </Container>
    );
}

export default Login;