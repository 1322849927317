import axios, {AxiosResponse} from 'axios';
import {GetDiaryResponse, OwnScholarsParams, OwnScholarsResponse} from "../components/utilities/constants";
import data from "../db.json";
const url = process.env.REACT_APP_API_URL;


export default class parentService {
    static getOwnChildren(params: OwnScholarsParams): Promise<OwnScholarsResponse> {
        return axios.get(`${url}/ownChildren`, { params }).then((response: AxiosResponse<OwnScholarsResponse>) => {
            console.log(response)
            return response.data;
        });
    }

    static testGetdata(): Promise<GetDiaryResponse> {
        return new Promise((resolve) => {
            resolve(data.notes as unknown as GetDiaryResponse);
        });
    }
}