import {ChildData, DiaryData, OwnScholarsResponse, UserRolesEnum} from "../../../utilities/constants";
import parentService from "../../../../services/parentService";
import React, {useEffect, useState} from "react";
import {useUser} from "../adminLayout";
import {Accordion} from "react-bootstrap";
import childService from "../../../../services/childService";
import moment from "moment";
import 'moment/locale/it';

function ConsultaDiario() {
    const { user, role, loggedUser } = useUser();
    const [ownChildren, setOwnChildren] = useState<ChildData[]>([]);
    const [childDiary, setChildDiary] = useState<{childId: string, diary: DiaryData[]}[]>([]);
    moment.locale('it');
    useEffect(() => {
        if (role === UserRolesEnum.Parent){
            getOwnChildren();
        }
    }, [role])
    const getOwnChildren = async () => {
        try {
            const response: OwnScholarsResponse = await parentService.getOwnChildren({
                paginate: 25,
                sortBy: 'last_name',
                sort: 'DESC',
                searchQuery: '',
                includeInactive: false
            });
            console.log(response.data);
            setOwnChildren(response.data)
        } catch (error) {
            console.error('Errore durante il recupero dei children del parent:', error);
        }
    }
    useEffect(() => {
        const getDiary = async () => {
            const promises = ownChildren.map(async (child) => {
                try {
                    const response = await childService.getDiary({}, child.id);
                    return { childId: child.id, diary: response.data };
                } catch (error) {
                    console.error('Errore durante il recupero del diario del bambino:', error);
                    return null;
                }
            });

            const diaries = await Promise.all(promises);
            const filteredDiaries = diaries.filter(diary => diary !== null) as { childId: string; diary: DiaryData[]; }[];
            setChildDiary(filteredDiaries);
            console.log(childDiary);
        };

        if (ownChildren.length > 0) {
            getDiary();
        }
    }, [ownChildren]);

    // Funzione di utilità per mappare i valori del cibo
    const mapFoodValue = (value: number): string => {
        switch (value) {
            case 0:
                return "Niente";
            case 1:
                return "Poco";
            case 2:
                return "Metà";
            case 3:
                return "Tutto";
            case 4:
                return "Bis";
            default:
                return "";
        }
    };
    // Funzione di utilità per mappare la qualità delle feci
    const mapFecesQuality = (value: number): string => {
        switch (value) {
            case 0:
                return "Liquida";
            case 1:
                return "Molle";
            case 2:
                return "Bella";
            default:
                return "";
        }
    };

    // Funzione di utilità per mappare la quantità di latte
    const mapMilkQuantity = (value: number): string => {
        switch (value) {
            case 0:
                return "Niente";
            case 1:
                return "Poco";
            case 2:
                return "Metà";
            case 3:
                return "Tutto";
            case 4:
                return "Bis";
            default:
                return "";
        }
    };//nap_1_quality

    // Funzione di utilità per mappare la quantità di sonno
    const mapNapQuality = (value: number): string => {
        switch (value) {
            case 0:
                return "Mezz'ora";
            case 1:
                return "1 ora";
            case 2:
                return "1 ora e mezza";
            case 3:
                return "2 ore";
            case 4:
                return "2 ore e mezza";
            case 5:
                return "3 ore";
            default:
                return "";
        }
    };

    return(
        <>
            <Accordion>
                {ownChildren.map((child) => (
                    <Accordion.Item key={child.id} eventKey={child.id}>
                        <Accordion.Header>{child.first_name}</Accordion.Header>
                        <Accordion.Body>
                            {childDiary
                                .filter((entry) => entry.childId === child.id)
                                .map((entry, index) => (
                                    <React.Fragment key={index}>
                                        {entry.diary.map((diaryEntry, diaryIndex) => (
                                            <Accordion key={diaryIndex}>
                                                <Accordion.Item eventKey={diaryIndex.toString()}>
                                                    <Accordion.Header>
                                                        <b className="tertiary">{moment(diaryEntry.created_at).format('DD MMMM YYYY')}</b>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p><b>Primo:</b> {mapFoodValue(diaryEntry.food_first_course)}</p>
                                                        <p><b>Secondo:</b> {mapFoodValue(diaryEntry.food_second_course)}</p>
                                                        <p><b>Contorno:</b> {mapFoodValue(diaryEntry.food_side)}</p>
                                                        <p><b>Latte:</b> {mapMilkQuantity(diaryEntry.milk)}</p>
                                                        <p><b>Cacca 1:</b> {mapFecesQuality(diaryEntry.feces_1_quality)}</p>
                                                        <p><b>Cacca 2:</b> {mapFecesQuality(diaryEntry.feces_2_quality)}</p>
                                                        <p><b>Nanna mattino:</b> {mapNapQuality(diaryEntry.nap_1_quality)}</p>
                                                        <p><b>Nanna pomeriggio:</b> {mapNapQuality(diaryEntry.nap_2_quality)}</p>
                                                        <p><b>Attività:</b> {diaryEntry.activities}</p>
                                                        <p><b>Note:</b> {diaryEntry.notes}</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        ))}
                                    </React.Fragment>
                                ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    )
}
export default ConsultaDiario;
