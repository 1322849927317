import { useState, useEffect } from 'react';

// Funzione per gestire la dimensione della finestra e il ridimensionamento delle icone
export function useWindowSizeAndIconSize(): string {
    const [width, setWidth] = useState<number>(window.innerWidth); // State per la larghezza della finestra

    // Funzione per gestire il cambio di dimensione della finestra
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    // Effetto per aggiungere e rimuovere il listener per il cambio di dimensione della finestra
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange); // Aggiungi il listener
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange); // Rimuovi il listener al momento dello smontaggio del componente
        }
    }, []); // Dipendenza vuota per eseguire l'effetto solo una volta all'inizio

    // Calcola se la larghezza è inferiore o uguale a 768 pixel
    const isSmallerOrEqual768: boolean = width <= 768;

    // Determina la dimensione dell'icona in base alla larghezza della finestra
    return isSmallerOrEqual768 ? "50%" : "30%";
}
