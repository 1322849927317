import React from "react";
import Container from "react-bootstrap/Container";

function Contatti() {
    require("./contatti.css")

    return(
        <>
            <Container className="py-3">
                <h2 className="text-center title">Contatti</h2>
                <div className="col-12 pb-3">
                    <p>L’asilo nido “Mano nella mano” è gestito dalla società “ A&C S.R.L.”</p>
                    <p>Sede legale: Piazza Roma 9, Mariano Comense (CO) – 22066</p>
                    <p>P.Iva 04074620131</p>
                    <p>Telefono: <a className="myTertiary" href="tel:3456590202">3456590202</a></p>
                    <p>Email: <a className="myTertiary" href="mailto:aec.asilinido@gmail.com">aec.asilinido@gmail.com</a></p>
                    <p>PEC: <a className="myTertiary" href="mailto:aec.asilinido@legalmail.it">aec.asilinido@legalmail.it</a></p>
                </div>

                <h3>Vieni a trovarci</h3>
                <div className="map-container">
                    <iframe
                        title="asilo nido Mano nella mano Mariano Comense - maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d696.6834611830576!2d9.178712269597819!3d45.6963130349024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786a2b07b0a3d4d%3A0xda3395c246cfa8b5!2sPiazza%20Roma%2C%207%2C%2022066%20Mariano%20Comense%20CO!5e0!3m2!1sit!2sit!4v1697137758102!5m2!1sit!2sit"
                        width="100%" height="100%" loading="lazy" allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"/>
                </div>

            </Container>
        </>
    );
}
export default Contatti;