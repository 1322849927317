import {Button, Container, FloatingLabel, Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import userService from "../../../../services/userService";

function ForgotPassword() {
    require("./forgot-password.css");
    let navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [pageToNavigate, setPageToNavigate] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await userService.resetPassword(email);
        if (response.status === 204) {
            setModalTitle('Email inviata');
            setModalBody('L\'email è stata inviata con successo.\nControlla la tua casella di posta per recuperare il link per modificare la tua password');
            setShowModal(true);
            console.log(response);
            console.log('Email inviata');
            setPageToNavigate('/login');
        } else if (response.status === 403 || response.status === 404 || response.status === 405) {
            setModalTitle('Errore nell\'invio mail');
            setModalBody(response.data?.message);
            setShowModal(true);
            console.error(response);
            //setPageToNavigate('/login');
        } else {
            setModalTitle('Errore nell\'invio mail');
            setModalBody(response.data?.message);
            setShowModal(true);
            console.error(response);
            //setPageToNavigate('/admin');
        }
    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
        if (error) {
            setError(false);
        }
    }

    const handleCloseModal = () => {
        if (pageToNavigate === "/login"){
            navigate("/login");
            window.location.reload();
        }
        setShowModal(false);
    }

    return (
        <>
            <Container fluid>
                <form onSubmit={handleSubmit} className="resetPasswordForm">
                    <h3 className="text-center mb-3">Richiedi nuova password</h3>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                    >
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={onChangeEmail}
                            autoComplete="true"
                        />
                    </FloatingLabel>

                    <button
                        type="submit"
                        className="pulse"
                        disabled={email.length < 1}>
                        Invia
                    </button>
                </form>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBody}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseModal}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ForgotPassword;